import { HiPencil, HiLockClosed } from 'react-icons/hi'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import GenerateIcon from '../../assets/icons/GenerateIcon.svg'
import Modal from './Popup'
import {
  COMMON_GENERATE_ESSAY_CLICK,
  COMMON_GENERATE_SUMMARY_CLICK,
  COMMON_MAIN_FORM_SIGNUP_CLICK,
} from '../../constants/posthog'
import {
  getAvailableQuota,
  getUserProfileData,
} from '../../features/user/userSlice'
import { useSelector } from 'react-redux'

const FormPrimaryButton = ({
  buttonLabel,
  loading,
  isAuthenticated,
  user,
  handleGenerateEssay,
  loginWithRedirect,
  formPreservingAuth,
  takeUserToStripe,
}) => {
  const userData = useSelector(getUserProfileData)
  const availableFreeEssaysQuota = useSelector(getAvailableQuota)

  console.log('inside button')
  return (
    <>
      {loading ? (
        // Essay is being generated. So, show a loader
        <button
          className="mr-2 inline-flex text-white bg-[#0027B3] border-0 py-2 px-4 focus:outline-none hover:bg-[#122362] rounded text-md w-full md:w-fit cursor-not-allowed"
          onClick={() => {}}
        >
          <div className="flex items-center space-x-2  mx-auto">
            <AiOutlineLoading3Quarters className="animate-spin cursor-pointer" />
            <label className="cursor-pointer">Generating</label>
          </div>
        </button>
      ) : isAuthenticated ? (
        // TODO: Uncomment the below line and remove the next to next line once email verification is brought back!
        // !user?.email_verified ? (
        !user?.email_verified === user?.email_verified ? (
          // Email is not verified. Show a grayed out button and disable button click
          <button
            className={`mr-2 inline-flex border-0 py-2 px-4 focus:outline-none rounded text-md text-gray-100 bg-gray-500 cursor-not-allowed w-full md:w-fit`}
            // onClick={handleGenerateEssay}
          >
            <div
              className="flex items-center space-x-2  mx-auto"
              ph-attr={
                buttonLabel === 'Essay'
                  ? COMMON_GENERATE_ESSAY_CLICK
                  : COMMON_GENERATE_SUMMARY_CLICK
              }
            >
              <HiPencil className="cursor-not-allowed" />
              <label className="cursor-not-allowed">
                Generate {buttonLabel}
              </label>
            </div>
          </button>
        ) : // Logged in + Email verified.
        userData?.is_member || parseInt(availableFreeEssaysQuota) > 0 ? (
          // Have an active subscription. Can generate unlimited essays!
          <button
            className={`mr-2 inline-flex border-0 py-2 px-4 focus:outline-none rounded text-md text-white bg-[#0027B3] hover:bg-[#122362] w-full md:w-fit`}
            onClick={handleGenerateEssay}
            ph-attr={
              buttonLabel === 'Essay'
                ? COMMON_GENERATE_ESSAY_CLICK
                : COMMON_GENERATE_SUMMARY_CLICK
            }
            type="submit"
          >
            <span className="flex items-center space-x-2 mx-auto">
              <img src={GenerateIcon} alt="Generate Icon" />
              <span className="cursor-pointer">Generate {buttonLabel}</span>
            </span>
          </button>
        ) : (
          // max essay limit reached
          <Modal takeUserToStripe={takeUserToStripe} />
        )
      ) : (
        // Not logged in user. Ask them to login.
        <button
          className="mr-2 inline-flex text-white bg-[#0027B3] border-0 py-2 px-4 focus:outline-none hover:bg-[#122362] rounded text-md w-full md:w-fit"
          onClick={() => {
            formPreservingAuth()
            loginWithRedirect({ screen_hint: 'signup' })
          }}
          ph-attr={COMMON_MAIN_FORM_SIGNUP_CLICK}
        >
          <div className="flex items-center space-x-2 mx-auto">
            <HiLockClosed className="cursor-pointer" />
            <label className="cursor-pointer">Sign Up to Continue</label>
          </div>
        </button>
      )}
    </>
  )
}

export default FormPrimaryButton

/* {loading ? (
    // Essay is being generated. So, show a loader
    <button
        className="mt-4 mr-2 inline-flex text-white bg-[#0027B3] border-0 py-2 px-4 focus:outline-none hover:bg-[#122362] rounded text-md w-full md:w-fit"
        onClick={() => {}}
    >
        <div className="flex items-center space-x-2  mx-auto">
        <AiOutlineLoading3Quarters className="animate-spin cursor-pointer" />
        <label className="cursor-pointer">Generating</label>
        </div>
    </button>
    ) : isAuthenticated ? (
    // TODO: Uncomment the below line and remove the next to next line once email verification is brought back!
    // !user?.email_verified ? (
    !user?.email_verified === user?.email_verified ? (
        // Email is not verified. Show a grayed out button and disable button click
        <button
        className={`mt-4 mr-2 inline-flex border-0 py-2 px-4 focus:outline-none rounded text-md text-gray-100 bg-gray-500 cursor-not-allowed w-full md:w-fit`}
        // onClick={handleGenerateEssay}
        >
        <div className="flex items-center space-x-2  mx-auto">
            <HiPencil className="cursor-not-allowed" />
            <label className="cursor-not-allowed">Generate Essay</label>
        </div>
        </button>
    ) : // Logged in + Email verified.
    userData?.is_member || parseInt(availableFreeEssaysQuota) > 0 ? (
        // Have an active subscription. Can generate unlimited essays!
        <button
        className={`mt-4 mr-2 inline-flex border-0 py-2 px-4 focus:outline-none rounded text-md text-white bg-[#0027B3] hover:bg-[#122362] w-full md:w-fit`}
        onClick={handleGenerateEssay}
        >
        <div className="flex items-center space-x-2 mx-auto">
            <HiPencil className="cursor-pointer" />
            <label className="cursor-pointer">Generate Essay</label>
        </div>
        </button>
    ) : (
        <button
        className={`mt-4 mr-2 inline-flex border-0 py-2 px-4 focus:outline-none rounded text-md text-gray-100 bg-gray-500 cursor-not-allowed  w-full md:w-fit`}
        // onClick={handleGenerateEssay}
        >
        <div className="flex items-center space-x-2 mx-auto">
            <HiPencil className="cursor-not-allowed" />
            <label className="cursor-not-allowed">Generate Essay</label>
        </div>
        </button>
    )
    ) : (
    // Not logged in user. Ask them to login.
    <button
        className="mt-4 mr-2 inline-flex text-white bg-[#0027B3] border-0 py-2 px-4 focus:outline-none hover:bg-[#122362] rounded text-md w-full md:w-fit"
        onClick={() => {
          formPreservingAuth() 
          loginWithRedirect({ screen_hint: 'signup' })
        }
        }
    >
        <div className="flex items-center space-x-2 mx-auto">
        <HiLockClosed className="cursor-pointer" />
        <label className="cursor-pointer">Sign Up to Continue</label>
        </div>
    </button>
    )} */
