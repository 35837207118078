import React, { useEffect, useState } from 'react'
// import parse from 'html-react-parser'
import GeneratedCompletionsComponent from './GeneratedCompletionsComponent'
import SideNavActionButtonForDesktop from '../desktop/SideNavActionButtonForDesktop'
import SideNavFooterForDesktop from '../desktop/SideNavFooterForDesktop'
import SidenavUpgradeCallout from './SidenavUpgradeCallout'
import SideNavAccountModalForMobile from '../mobile/SideNavAccountModalForMobile'
import axios from 'axios'
import SideNavActionButtonForMobile from '../mobile/SideNavActionButtonForMobile'
// import EssayForm from './EssayForm'
import TrialPlanOverviewCallout from '../desktop/TrialPlanOverviewCallout'
import MainFormComponent from '../mobile/MainFormComponent'
import { FixedBottom } from 'react-fixed-bottom'
import HubbleLogo from '../../assets/icons/HubbleLogo.svg'
import { useAuth0 } from '@auth0/auth0-react'
import {
  getAvailableQuota,
  getUserProfileData,
} from '../../features/user/userSlice'
import { useSelector } from 'react-redux'

const SideNav = ({
  isLoadingUserData,
  accessToken,
  user,
  loginWithRedirect,
  formPreservingAuth,
  takeUserToStripe,
  generatedCompletions,
  logout,
  essayTopic,
  essayDetails,
  essayLength,
  generatedEssay,
  setGeneratedEssay,
  setEssayTopic,
  setEssayDetails,
  setEssayLength,
  summaryPrompt,
  setSummaryPrompt,
  generatedSummary,
  setGeneratedSummary,
  handleGenerateCompletion,
  loadingCompletions,
  resendVerificationEmail,
  setShowInputForm,
  showInputForm,
  setSelectedCompletion,
  selectedCompletion,
  deleteCompletion,
}) => {
  const [showForm, setShowForm] = useState(false)
  const [useTransitions, setUseTransitions] = useState(true)

  const { isLoading: isAuthLoading, isAuthenticated } = useAuth0()
  const userData = useSelector(getUserProfileData)
  const availableFreeEssaysQuota = useSelector(getAvailableQuota)
  const { getAccessTokenSilently } = useAuth0()

  // useEffect(() => {
  //   setFilteredCompletions(generatedCompletions)
  // }, [generatedCompletions])

  useEffect(() => {
    if (!isAuthLoading && !isLoadingUserData) {
      if (
        !isAuthenticated ||
        (isAuthenticated &&
          availableFreeEssaysQuota === process.env.REACT_APP_HUBBLE_FREE_ESSAYS)
      ) {
        // console.log('New usr or guest flow')
        setShowForm(true)
        setUseTransitions(false)
      } else {
        setUseTransitions(true)
      }
    }
  }, [
    isAuthLoading,
    isAuthenticated,
    availableFreeEssaysQuota,
    isLoadingUserData,
  ])

  const clearEssayForm = () => {
    setEssayTopic('')
    setEssayDetails('')
    setEssayLength('')
    setGeneratedEssay(null)
    setShowInputForm(true)
    setSelectedCompletion(null)
  }

  const clearSummaryForm = () => {
    setSummaryPrompt('')
    setGeneratedSummary(null)
    setShowInputForm(true)
    setSelectedCompletion(null)
  }

  const takeUserToStripeDashboard = async () => {
    const response = await axios.post(
      process.env.REACT_APP_HUBBLE_API_BASE_URL +
        '/create_customer_portal_session',
      {},
      {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }
    )

    window.location.href = response?.data?.url
  }

  const toggleShowForm = () => {
    if (!isAuthLoading && !isAuthenticated) {
      setUseTransitions(false)
      setShowForm(true)
      return
    }
    setShowForm((prevState) => !prevState)
  }

  // const filterGeneratedCompletions = (e) => {
  //   setFilteredCompletions(
  //     generatedCompletions
  //       .map(
  //         (completion) =>
  //           completion?.input?.prompt?.[0] +
  //           ' ' +
  //           parse(completion?.output?.choices?.[0]?.text || '')
  //       )
  //       .filter(
  //         (processedCompletion) =>
  //           processedCompletion.search(e.target.value) >= 0
  //       )
  //   )
  // }

  if (isAuthLoading) return <h1>Loading..</h1>

  return (
    <nav className="flex flex-col h-screen md:border-r md:border-[#EBECED] w-full md:w-5/12 lg:w-3/12 bg-[#FFFFFF] ">
      {/* Nav header. This holds the logo + Title, Login / Signup and avatar */}
      <div className="flex px-5 py-2 border-b items-center justify-between w-full bg-white">
        {/* Hubble Logo */}
        <h1 className="flex text-base font-normal text-gray-900 items-center space-x-1 py-1">
          <img
            src={HubbleLogo}
            alt="Hubble Logo"
            className="w-8 h-8 px-1 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white md:w-8 md:h-8"
          />
          <label className="font-bold text-lg">Hubble</label>
        </h1>

        {/* Visible only in Mobile view */}
        {isAuthenticated ? (
          <SideNavAccountModalForMobile
            user={user}
            takeUserToStripe={takeUserToStripe}
            takeUserToStripeDashboard={takeUserToStripeDashboard}
            logout={logout}
          />
        ) : (
          <div className="flex-row flex md:hidden space-x-2">
            {/* Sign up Button in Mobile View */}
            <button
              className="px-0.5 py-1.5 rounded text-base font-normal text-gray-500 bg-white border"
              onClick={() => {
                formPreservingAuth()
                loginWithRedirect({ screen_hint: 'signup' })
              }}
            >
              <span className="px-4 whitespace-nowrap">Sign up</span>
            </button>

            {/* Login button in Mobile View */}
            <button
              className="px-2 py-1.5 text-base border border-[#0027B3] font-normal focus:outline-none rounded text-white bg-[#0027B3] hover:bg-[#122362]"
              onClick={() => {
                formPreservingAuth()
                loginWithRedirect()
              }}
            >
              <span className="px-4">Login</span>
            </button>
          </div>
        )}
      </div>

      {/* Testing the new layout setup for better transitions! */}
      <div className="flex flex-row sticky overflow-y-scroll z-0 overflow-x-hidden grow">
        <div className="flex flex-col w-full">
          <div className="sticky w-full top-0">
            {isAuthenticated && !userData?.is_member && (
              <SidenavUpgradeCallout takeUserToStripe={takeUserToStripe} />
            )}
            {/* <div className="w-full border-b flex flex-col items-center justify-center py-3 px-6 bg-white">
              <div className="flex flex-row w-full items-center bg-[#F5F6F7] rounded-lg space-x-2 px-2 py-2">
                <HiSearch />
                <input
                  type="text"
                  className="w-full bg-[#F5F6F7] focus:outline-none"
                  placeholder="Search"
                  onChange={filterGeneratedCompletions}
                />
              </div>
            </div> */}
          </div>

          <GeneratedCompletionsComponent
            generatedCompletions={generatedCompletions}
            // generatedCompletions={filteredCompetions}
            toggleShowForm={toggleShowForm}
            setEssayTopic={setEssayTopic}
            setEssayDetails={setEssayDetails}
            setEssayLength={setEssayLength}
            setGeneratedEssay={setGeneratedEssay}
            setSummaryPrompt={setSummaryPrompt}
            setGeneratedSummary={setGeneratedSummary}
            setShowInputForm={setShowInputForm}
            setSelectedCompletion={setSelectedCompletion}
            selectedCompletion={selectedCompletion}
            deleteCompletion={deleteCompletion}
          />

          <FixedBottom>
            <SideNavActionButtonForMobile
              toggleShowForm={toggleShowForm}
              clearEssayForm={clearEssayForm}
              clearSummaryForm={clearSummaryForm}
              setSelectedCompletion={setSelectedCompletion}
            />
          </FixedBottom>

          <SideNavActionButtonForDesktop
            clearEssayForm={clearEssayForm}
            clearSummaryForm={clearSummaryForm}
            setSelectedCompletion={setSelectedCompletion}
          />

          <TrialPlanOverviewCallout />

          {/* Visible only on desktops */}
          {/* {isAuthenticated && !userData?.is_member && ( */}
          <SideNavFooterForDesktop
            loginWithRedirect={loginWithRedirect}
            formPreservingAuth={formPreservingAuth}
            isAuthenticated={isAuthenticated}
            user={user}
            takeUserToStripeDashboard={takeUserToStripeDashboard}
            logout={logout}
          />
          {/* )} */}
        </div>

        <div
          className={`transform top-0 left-0 w-full bg-white h-full md:hidden fixed overflow-auto z-30 ${
            showForm ? '-translate-x-0' : 'translate-x-full'
          } ${useTransitions ? 'ease-in-out transition-all duration-1000' : ''}
          
          `}
        >
          {/* ${showFullPage ? 'fixed' : 'absolute'} */}
          <MainFormComponent
            essayTopic={essayTopic}
            setEssayTopic={setEssayTopic}
            essayDetails={essayDetails}
            setEssayDetails={setEssayDetails}
            essayLength={essayLength}
            setEssayLength={setEssayLength}
            loadingCompletions={loadingCompletions}
            isAuthenticated={isAuthenticated}
            user={user}
            loginWithRedirect={loginWithRedirect}
            formPreservingAuth={formPreservingAuth}
            resendVerificationEmail={resendVerificationEmail}
            takeUserToStripe={takeUserToStripe}
            generatedEssay={generatedEssay}
            setGeneratedEssay={setGeneratedEssay}
            accessToken={accessToken}
            handleGenerateCompletion={handleGenerateCompletion}
            toggleShowForm={toggleShowForm}
            showInputForm={showInputForm}
            setShowInputForm={setShowInputForm}
            summaryPrompt={summaryPrompt}
            setSummaryPrompt={setSummaryPrompt}
            generatedSummary={generatedSummary}
            setGeneratedSummary={setGeneratedSummary}
            selectedCompletion={selectedCompletion}
            deleteCompletion={deleteCompletion}
          />
          {/* <EssayForm
            essayTopic={essayTopic}
            setEssayTopic={setEssayTopic}
            essayDetails={essayDetails}
            setEssayDetails={setEssayDetails}
            essayLength={essayLength}
            setEssayLength={setEssayLength}
            loadingCompletions={loadingCompletions}
            isAuthenticated={isAuthenticated}
            user={user}
            loginWithRedirect={loginWithRedirect}
            formPreservingAuth={formPreservingAuth}
            resendVerificationEmail={resendVerificationEmail}
            takeUserToStripe={takeUserToStripe}
            generatedEssay={generatedEssay}
            setGeneratedEssay={setGeneratedEssay}
            accessToken={accessToken}
            handleGenerateCompletion={handleGenerateCompletion}
            toggleShowForm={toggleShowForm}
          /> */}
        </div>
      </div>
    </nav>
  )
}

export default SideNav
