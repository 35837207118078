// Generate and Save API related
export const OUTPUT_TYPE = {
  ACADEMIC_ESSAY: 'ACADEMIC_ESSAY',
  LOVE_LETTER: 'LOVE_LETTER',
  OUTLINE: 'OUTLINE',
  SCHOOL_APPLICATION: 'SCHOOL_APPLICATION',
  SUMMARY: 'SUMMARY',
  TEXT_COMPLETION: 'TEXT_COMPLETION',
}

export const DEFAULT_COMPLETION_LENGTH = 500
export const COMPLETION_RESPONSE_V1 = 'V1'
export const COMPLETION_RESPONSE_V2 = 'V2'
