import React from 'react'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { Link } from 'react-router-dom'
import SummaryIcon from '../../assets/icons/SummaryIcon.svg'
import EssayIcon from '../../assets/icons/EssayIcon.svg'

const ActionToggle = ({ clearEssayForm, clearSummaryForm, position }) => {
  return (
    <div className="rounded-md justify-center bg-[#F5F6F7] border-[#EBECED] px-1 w-full md:w-3/5 lg:w-2/5 flex">
      <ToggleGroup.Root
        className="ToggleGroup flex w-full"
        type="single"
        value={position}
        aria-label="Text alignment"
      >
        <ToggleGroup.Item
          className="ToggleGroupItem grow hover:bg-[#DEE5FC] bg-white my-1 rounded-md mr-2 w-1/2"
          value="left"
          aria-label="Left aligned"
        >
          <Link
            className="py-2 text-lg px-2 flex justify-center"
            to="/"
            onClick={clearEssayForm}
          >
            <img src={EssayIcon} alt="Essay icon" className="w-5" />
            <h1 className="ml-3 font-semibold text-md">Essay</h1>
          </Link>
        </ToggleGroup.Item>
        <ToggleGroup.Item
          className="ToggleGroupItem grow hover:bg-[#DEE5FC] bg-white my-1 rounded-md w-1/2"
          value="right"
          aria-label="Right aligned"
        >
          <Link
            className="py-2 text-lg px-2 flex justify-center"
            to="/summarize"
            onClick={clearSummaryForm}
          >
            <img src={SummaryIcon} alt="Summary icon" className="w-5" />
            <h1 className="ml-3 font-semibold text-md">Summary</h1>
          </Link>
        </ToggleGroup.Item>
      </ToggleGroup.Root>
    </div>
  )
}

export default ActionToggle
