import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import {
  // HiOutlineCurrencyDollar,
  // HiOutlineLogout,
  HiUserCircle,
} from 'react-icons/hi'
import SidenavUpgradeCallout from '../common/SidenavUpgradeCallout'
import { MdOutlineClose } from 'react-icons/md'
import PaymentIcon from '../../assets/icons/PaymentIcon.svg'
import LogoutIcon from '../../assets/icons/LogoutIcon.svg'
import {
  MOBILE_MY_ACCOUNT_MODAL_CLOSE_CLICK,
  MOBILE_SIDENAV_VIEWPAYMENTS_CLICK,
  MOBILE_USER_AVATAR_CLICK,
} from '../../constants/posthog'
import { getUserProfileData } from '../../features/user/userSlice'
import { useSelector } from 'react-redux'

export default function SideNavAccountModalForMobile({
  user,
  takeUserToStripe,
  takeUserToStripeDashboard,
  logout,
}) {
  let [isOpen, setIsOpen] = useState(false)
  const userData = useSelector(getUserProfileData)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <div
        className="sticky flex items-center justify-center"
        ph-attr={MOBILE_USER_AVATAR_CLICK}
      >
        {user?.picture ? (
          <img
            src={user?.picture}
            alt="Avatar"
            className="md:hidden rounded-full w-8 h-8"
            onClick={openModal}
          />
        ) : (
          <HiUserCircle
            className="md:hidden rounded-full w-8 h-8"
            onClick={openModal}
          />
        )}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed bottom-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen transform overflow-hidden bg-[#FBFBFA] p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="pb-4 flex flex-row justify-between items-center">
                      <h2 className="font-medium text-md">My Account</h2>
                      <button
                        onClick={closeModal}
                        ph-attr={MOBILE_MY_ACCOUNT_MODAL_CLOSE_CLICK}
                      >
                        <MdOutlineClose className="w-5 h-5 text-[#9B9B9B]" />
                      </button>
                    </div>
                  </Dialog.Title>

                  {/* Modal Body */}
                  <div className="mt-2 flex flex-col space-y-4">
                    {/* User Account element */}
                    <div className="border bg-white flex flex-row items-center p-4 gap-3 w-full rounded-lg">
                      {user?.picture ? (
                        <img
                          src={user?.picture}
                          alt="Avatar"
                          className="rounded-full w-8 h-8 mr-2"
                        />
                      ) : (
                        <HiUserCircle className="rounded-full w-8 h-8 mr-2" />
                      )}
                      <div className="flex flex-col items-start">
                        {/*<label>{user?.nickname}</label>*/}
                        <label className="text-black">{user?.email}</label>
                      </div>
                    </div>

                    {/* Payment Callout */}
                    {!userData?.is_member && (
                      <div
                        className="border rounded-lg"
                        ph-attr={MOBILE_SIDENAV_VIEWPAYMENTS_CLICK}
                      >
                        <SidenavUpgradeCallout
                          takeUserToStripe={takeUserToStripe}
                          bgColor="white"
                        />
                      </div>
                    )}

                    {/* Signout Button */}
                    <div className="border rounded-lg flex flex-col divide-y w-full bg-white">
                      {userData?.is_member && (
                        <div
                          className="flex flex-row items-center p-4 gap-2"
                          onClick={takeUserToStripeDashboard}
                        >
                          <img src={PaymentIcon} alt="Payment Icon" />
                          <label className="ml-2 text-sm font-light text-black -mt-1">
                            Payment
                          </label>
                        </div>
                      )}
                      <div
                        className="flex flex-row items-center p-4 gap-2"
                        onClick={() => {
                          logout({ returnTo: window.location.origin })
                        }}
                      >
                        <img src={LogoutIcon} alt="Logout Icon" />
                        <label className="ml-2 text-sm font-light text-black -mt-1">
                          Logout
                        </label>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
