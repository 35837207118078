import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useCookies } from 'react-cookie'
import { Routes, Route } from 'react-router-dom'
import Essays from './pages/Essays'
import Summary from './pages/Summary'
import Layout from './pages/Layout'
import AcademicEssayForm from './components/common/forms/AcademicEssayForm'
import Writer from './pages/Writer'

function App() {
  const [cookies, setCookie] = useCookies(['loggedInFreeEssays'])

  useEffect(() => {
    if (cookies?.loggedInFreeEssays === undefined)
      setCookie('loggedInFreeEssays', 3, {
        path: '/',
      })
  }, [cookies?.loggedInFreeEssays, setCookie])

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Essays />} />
          <Route path="writer" element={<Writer />} />
          <Route path="canceled" element={<Essays />} />
          <Route path="essays" element={<Essays />} />
          <Route path="success" element={<Essays />} />
          <Route path="summarize" element={<Summary />} />
        </Route>
        <Route path="/roadmap" element={<div data-productlift></div>}></Route>

        {/* TODO: Create a new 404 page */}
        <Route
          path="*"
          element={<h1>Couldn't find the page you are looking for! </h1>}
        />
      </Routes>

      <ToastContainer />
    </div>
  )
}

export default App
