import React from 'react';
import UpgradeIcon from '../../assets/icons/UpgradeIcon.svg'

export default function Modal({takeUserToStripe}) {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <button
        className="hidden md:visible mr-2 py-2 px-4 md:inline-flex items-center justify-center font-semibold border-0 focus:outline-none rounded text-md text-white bg-[#0027B3] hover:bg-[#122362] w-full md:w-fit"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <img src={UpgradeIcon} alt="Upgrade Icon" className="mr-2"/>  
        Upgrade Now
      </button>
      {showModal ? (
        <>
          <div
            className="hidden justify-center place-items-center fixed md:flex overflow-x-hidden overflow-y-hidden h-screen inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="bg-gradient-to-r from-[#0027B3]/10 to-[#F1CD61]/20 border-t-8 border-[#0027B3] rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between py-5 mx-8 border-b border-solid border-[#DEE5FC] rounded-t">
                  <h3 className="text-2xl font-newkansas font-semibold">
                   Ready to Upgrade?
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto">
                  <p className="my-4 mx-8 text-black font-semibold leading-relaxed">
                    You have hit the limit for free documents, but you can upgrade for: 
                  </p>
                  <ul className="relative flex-auto list-disc">
                        <li className="my-2 mx-14 text-black font-normal leading-relaxed">Unlimited Essays</li>
                        <li className="my-2 mx-14 text-black font-normal leading-relaxed">Unlimited Summaries</li>
                  </ul>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-normal px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="inline-flex items-center justify-center bg-[#0027B3] text-white font-semibold text-normal px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={takeUserToStripe}
                  >
                  <img src={UpgradeIcon} alt="Upgrade Icon" className="mr-2"/>  
                    Upgrade Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 absolute inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}