import { useOutletContext } from 'react-router-dom'
import SummaryForm from '../components/common/SummaryForm'

const Summary = () => {
  const {
    user,
    summaryPrompt,
    setSummaryPrompt,
    generatedSummary,
    setGeneratedSummary,
    isAuthenticated,
    loginWithRedirect,
    formPreservingAuth,
    handleGenerateCompletion,
    loadingCompletions,
    resendVerificationEmail,
    takeUserToStripe,
    showInputForm,
    setShowInputForm,
  } = useOutletContext()

  return (
    <SummaryForm
      user={user}
      summaryPrompt={summaryPrompt}
      setSummaryPrompt={setSummaryPrompt}
      generatedSummary={generatedSummary}
      setGeneratedSummary={setGeneratedSummary}
      isAuthenticated={isAuthenticated}
      loginWithRedirect={loginWithRedirect}
      formPreservingAuth={formPreservingAuth}
      handleGenerateCompletion={handleGenerateCompletion}
      loadingCompletions={loadingCompletions}
      resendVerificationEmail={resendVerificationEmail}
      takeUserToStripe={takeUserToStripe}
      showInputForm={showInputForm}
      setShowInputForm={setShowInputForm}
    />
  )
}

export default Summary
