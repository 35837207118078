import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const GeneratedAt = ({ selectedCompletion }) => {
  return (
    <label className="px-2 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white align-middle py-1 font-xs text-sm md:text-md">
      Generated {dayjs(selectedCompletion?.created_at).fromNow()}
    </label>
  )
}

export default GeneratedAt
