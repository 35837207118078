import React from 'react'
import { useOutletContext } from 'react-router-dom'

import EssayForm from '../components/common/EssayForm'

const Essays = () => {
  const {
    user,
    essayTopic,
    essayDetails,
    essayLength,
    generatedEssay,
    setEssayTopic,
    setEssayDetails,
    setEssayLength,
    setGeneratedEssay,
    isAuthenticated,
    loginWithRedirect,
    formPreservingAuth,
    accessToken,
    handleGenerateCompletion,
    loadingCompletions,
    resendVerificationEmail,
    takeUserToStripe,
    showInputForm,
    setShowInputForm,
  } = useOutletContext()

  return (
    <EssayForm
      essayTopic={essayTopic}
      setEssayTopic={setEssayTopic}
      essayDetails={essayDetails}
      setEssayDetails={setEssayDetails}
      essayLength={essayLength}
      setEssayLength={setEssayLength}
      loadingCompletions={loadingCompletions}
      isAuthenticated={isAuthenticated}
      user={user}
      loginWithRedirect={loginWithRedirect}
      formPreservingAuth={formPreservingAuth}
      resendVerificationEmail={resendVerificationEmail}
      takeUserToStripe={takeUserToStripe}
      generatedEssay={generatedEssay}
      setGeneratedEssay={setGeneratedEssay}
      accessToken={accessToken}
      handleGenerateCompletion={handleGenerateCompletion}
      showInputForm={showInputForm}
      setShowInputForm={setShowInputForm}
    />
  )
}

export default Essays
