import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  requestsCount: 0,
}

export const userRequestsSlice = createSlice({
  name: 'userRequestCounter',
  initialState,
  reducers: {
    updateRequestCount: (state, action) => {
      state.requestsCount = action.payload.requestCount
    },
  },
})

export const { updateRequestCount } = userRequestsSlice.actions
export default userRequestsSlice.reducer
