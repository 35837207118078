import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { useState } from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { CheckIcon, UpgradeIcon } from '../../assets/icons'
import { DESKTOP_TOPNAV_UPGRADE_CLICK } from '../../constants'
import { getAvailableQuota } from '../../features/user/userSlice'

export const ProgressBar = (active, total) => {
  const [filled, setFilled] = useState(total - active)
}

export default function TopNavUpgradeCallout({ takeUserToStripe }) {
  const availableFreeEssaysQuota = useSelector(getAvailableQuota)
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? '' : 'text-opacity-90'}
                border-[#DEE5FC] border-1.5 group inline-flex items-center rounded-sm bg-[#F7F7FD] px-3 py-2 text-xs font-medium hover:text-opacity-100 focus:outline-none text-[#0027B3]`}
          >
            <div className="flex items-center space-x-1.5">
              <UpgradeIcon className="fill-[#0027B3] w-3 h-3 mr-0.5" />
              <span className="">
                {Math.min(
                  process.env.REACT_APP_HUBBLE_FREE_ESSAYS -
                    availableFreeEssaysQuota,
                  process.env.REACT_APP_HUBBLE_FREE_ESSAYS
                )}{' '}
                of {process.env.REACT_APP_HUBBLE_FREE_ESSAYS} free documents
                used
              </span>
              <ChevronDownIcon className="fill-[#0027B3] w-3 h-3" />
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-full -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-lg shadow-lg w-80 border-t-8 border-[#0027B3]">
                <div className="bg-white px-4 pt-4 pb-2 flex flex-col justify-center gap-3">
                  <span className="text-normal text-black font-semibold">
                    {Math.min(
                      process.env.REACT_APP_HUBBLE_FREE_ESSAYS -
                        availableFreeEssaysQuota,
                      process.env.REACT_APP_HUBBLE_FREE_ESSAYS
                    )}{' '}
                    of {process.env.REACT_APP_HUBBLE_FREE_ESSAYS} Free Documents
                    Used
                  </span>

                  <span className="mt-1 mb-2 text-sm text-black font-normal">
                    The Hubble Free Plan is limited to{' '}
                    {process.env.REACT_APP_HUBBLE_FREE_ESSAYS} free documents.
                    Upgrade today for:
                  </span>

                  <ul className="text-sm text-black mb-2 font-normal">
                    <li className="flex items-center space-x-2">
                      <CheckIcon className="fill-black" />{' '}
                      <span>Unlimited essays</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <CheckIcon className="fill-black" />{' '}
                      <span>Unlimited summaries</span>
                    </li>
                    {/* <li className="flex items-center space-x-2">
                      <CheckIcon className="fill-black" />{' '}
                      <span>Unlimited plagiarism checks</span>
                    </li> */}
                  </ul>

                  <button
                    className={`mb-2 mr-2 inline-flex border-0 py-2 px-4 focus:outline-none rounded text-md text-white bg-[#0027B3] hover:bg-[#122362] w-full`}
                    onClick={takeUserToStripe}
                    ph-attr={DESKTOP_TOPNAV_UPGRADE_CLICK}
                  >
                    <div className="flex items-center space-x-2 justify-center w-full">
                      <UpgradeIcon className="fill-white w-3 h-3" />
                      <label className="cursor-pointer">Upgrade Plan</label>
                    </div>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
