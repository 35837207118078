import React from 'react'
// import { HiX } from 'react-icons/hi'
// import { IoMdDocument } from 'react-icons/io'
import { Outlet } from 'react-router-dom'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import GeneratedAt from '../common/GeneratedAt'
import HistoricalItemActionButton from './HistoricalItemActionButton'
import {
  getTotalGeneratedOutputsCount,
  getUserProfileData,
} from '../../features/user/userSlice'
import { useSelector } from 'react-redux'
// import ActionToggle from '../common/ActionToggle'

const MainFormComponent = ({
  user,
  essayTopic,
  essayDetails,
  essayLength,
  generatedEssay,
  setGeneratedEssay,
  setEssayTopic,
  setEssayDetails,
  setEssayLength,
  summaryPrompt,
  setSummaryPrompt,
  generatedSummary,
  setGeneratedSummary,
  isAuthenticated,
  loginWithRedirect,
  formPreservingAuth,
  accessToken,
  handleGenerateCompletion,
  loadingCompletions,
  resendVerificationEmail,
  takeUserToStripe,
  toggleShowForm,
  showInputForm,
  setShowInputForm,
  selectedCompletion,
  deleteCompletion,
}) => {
  const userData = useSelector(getUserProfileData)
  const totalGeneratedOutputs = useSelector(getTotalGeneratedOutputsCount)

  return (
    <div className="flex flex-col w-full items-start px-8 py-4 md:py-10 md:px-28 gap-2 bg-white">
      <div className="flex flex-row items-center w-full pb-3 border-b">
        {isAuthenticated && totalGeneratedOutputs !== 0 && (
          <div className="text-2xl items-center gap-2 w-full flex flex-row justify-between">
            <div className="flex text-2xl items-center gap-2">
              <MdKeyboardArrowLeft
                onClick={toggleShowForm}
                className=" -ml-2 md:hidden text-xl"
              />
              {selectedCompletion ? (
                <GeneratedAt selectedCompletion={selectedCompletion} />
              ) : (
                <p onClick={toggleShowForm} className="text-sm font-semibold">
                  View Documents{' '}
                </p>
              )}

              {/* <IoMdDocument className="text-[#0027B3]" /> */}
              {/* <label className="font-semibold">Generate Document</label> */}
            </div>

            {selectedCompletion && !showInputForm && (
              <HistoricalItemActionButton
                selectedCompletion={selectedCompletion}
                takeUserToStripe={takeUserToStripe}
                deleteCompletion={deleteCompletion}
              />
            )}
          </div>
        )}

        {/*<h1 className="flex text-2xl items-center gap-2 grow">
          <IoMdDocument className="text-[#0027B3]" />
          <label>New Document</label>
      </h1>*/}
      </div>

      {/*<h1 className="flex text-2xl pt-4 items-center gap-2 grow">
          <IoMdDocument className="text-[#0027B3]" />
          <label>New Document</label>
    </h1>*/}

      <Outlet
        context={{
          user,
          userData,
          essayTopic,
          essayDetails,
          essayLength,
          generatedEssay,
          setGeneratedEssay,
          setEssayTopic,
          setEssayDetails,
          setEssayLength,
          summaryPrompt,
          setSummaryPrompt,
          generatedSummary,
          setGeneratedSummary,
          isAuthenticated,
          loginWithRedirect,
          formPreservingAuth,
          accessToken,
          handleGenerateCompletion,
          loadingCompletions,
          resendVerificationEmail,
          takeUserToStripe,
          showInputForm,
          setShowInputForm,
        }}
      />
    </div>
  )
}

export default MainFormComponent
