import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchUserData,
  getUserProfileData,
  fetchAvailableFreeQuota,
  fetchGeneratedOutputsCount,
  updateAvailableQuotaByValue,
} from '../features/user/userSlice'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import SideNav from '../components/common/SideNav'
import { Outlet, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { supabase } from '../supbaseClient'
import PlagiarismCheckerForDesktop from '../components/desktop/PlagiarismCheckerForDesktop'
import PlagiarismCheckerForMobile from '../components/mobile/PlagiarismCheckerForMobile'
// import AnnounceIcon from '../assets/icons/AnnounceIcon.svg'
// import UpgradeIcon from '../assets/icons/UpgradeIcon.svg'
// import { TrashIcon } from '../assets/icons/temp.index'

import { useCookies } from 'react-cookie'
// import CookieBanner from '../components/common/CookieBanner'
// import posthog from 'posthog-js'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { OUTPUT_TYPE } from '../constants/contractEnums'
import TopNavUpgradeCallout from '../components/desktop/TopNavUpgradeCallout'
import { TrashIcon } from '../assets/icons'
import GeneratedAt from '../components/common/GeneratedAt'
import Loader from '../components/Loader'
import { DESKTOP_DELETE_OUTPUT_ACTION } from '../constants'
dayjs.extend(relativeTime)

const saveToSupabase = async (
  table,
  request_payload,
  response_payload,
  requestor_email
) => {
  return null
  // await supabase.from(table).insert([
  //   {
  //     request_payload,
  //     response_payload,
  //     essay_content: response_payload?.data,
  //     env: process.env.REACT_APP_ENVIRONMENT,
  //     requestor_email: requestor_email,
  //   },
  // ])
}

const Layout = () => {
  const userData = useSelector(getUserProfileData)
  const dispatch = useDispatch()

  const [accessToken, setAccessToken] = useState()
  const [generatedCompletions, setGeneratedCompletions] = useState([])
  const [selectedCompletion, setSelectedCompletion] = useState()
  const [showBottomModal, setShowBottomModal] = useState(false)

  const [essayTopic, setEssayTopic] = useState('')
  const [essayDetails, setEssayDetails] = useState('')
  const [essayLength, setEssayLength] = useState('')
  const [generatedEssay, setGeneratedEssay] = useState(null)

  const [summaryPrompt, setSummaryPrompt] = useState('')
  // const [summaryLength, setSummaryLength] = useState()
  const [generatedSummary, setGeneratedSummary] = useState(null)

  const [loadingCompletions, setLoadingCompletions] = useState(false)
  const [showInputForm, setShowInputForm] = useState(true)
  const [isLoadingUserData, setIsLoadingUserData] = useState(true)

  const [cookies, setCookie] = useCookies([
    'essayTopic',
    'essayDetails',
    'essayLength',
    'summaryPrompt',
    'loadFromCookies',
  ])

  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
    // ...others
  } = useAuth0()

  const formPreservingAuth = async () => {
    if (!isLoading && !isAuthenticated) {
      setCookie('essayTopic', essayTopic, {
        path: '/',
      })

      setCookie('essayDetails', essayDetails, {
        path: '/',
      })

      setCookie('essayLength', essayLength, {
        path: '/',
      })

      setCookie('summaryPrompt', summaryPrompt, {
        path: '/',
      })

      setCookie(
        'loadFromCookies',
        (essayTopic || essayDetails || essayLength || summaryPrompt) &&
          !isAuthenticated
          ? true
          : false,
        {
          path: '/',
        }
      )
    }
  }

  // Helper method to send email verification to the user.
  const resendVerificationEmail = async () => {
    const resendEmailVerificationResponse = await axios.post(
      process.env.REACT_APP_HUBBLE_API_BASE_URL + '/resend_verification_email',
      {},
      {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }
    )

    if (resendEmailVerificationResponse?.data?.success)
      toast.success('Email sent. Please check your inbox and spam folders!')
    else toast.error('There is an error. Please try again later!')
  }

  // Get the user completions from our BE
  const getUserCompletions = async () => {
    const completionsResponse = await axios.get(
      process.env.REACT_APP_HUBBLE_API_BASE_URL + '/outputs',
      {
        params: {
          limit: 500,
          order_dir: 'desc',
          order_by: 'created_at',
        },
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }
    )
    setGeneratedCompletions(completionsResponse.data.data)
  }

  const clearForm = (completionType) => {
    if (
      completionType === OUTPUT_TYPE.ACADEMIC_ESSAY ||
      completionType === OUTPUT_TYPE.TEXT_COMPLETION
    ) {
      setEssayTopic('')
      setEssayDetails('')
      setEssayLength('')
      setGeneratedEssay()
    } else if (completionType === OUTPUT_TYPE.SUMMARY) {
      setGeneratedSummary()
      setSummaryPrompt('')
    }
  }

  // Delete a completion
  const deleteCompletion = async () => {
    // console.log('Deleting :', selectedCompletion)
    const deleteCompletionResponse = await axios.delete(
      process.env.REACT_APP_HUBBLE_API_BASE_URL +
        `/output/${selectedCompletion?.id}`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }
    )

    // console.log(deleteCompletionResponse)
    if (
      deleteCompletionResponse.status === 200 &&
      deleteCompletionResponse?.data?.success
    ) {
      toast.success('Document deleted successfully!')
      clearForm(selectedCompletion?.output_type)
      setShowInputForm(true)
      setSelectedCompletion(null)
    } else {
      toast.error(
        'There is an error deleting your document. Please try again later!'
      )
    }
    dispatch(fetchAvailableFreeQuota(await getAccessTokenSilently()))
    getUserCompletions()
    setLoadingCompletions(false)
  }

  // When user clicks this callout link(in sidenav),
  // we will hit our BE API to get the dynamic Stripe check-out session.
  // Then take the user to that page. On returning, we will show a
  // corresponding notification or alert!
  const takeUserToStripe = async () => {
    const payload = {
      price_ids: [process.env.REACT_APP_STRIPE_PRICE_ID],
    }

    const response = await axios.post(
      process.env.REACT_APP_HUBBLE_API_BASE_URL + '/create_checkout_session',
      payload,
      {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }
    )

    window.location.href = response?.data?.url
  }

  const toggleBottomModal = () => {
    setShowBottomModal((prevState) => !prevState)
  }

  // Helper method to get completions through our backend
  const handleGenerateCompletionOld = async (
    prompt,
    completionLength,
    completionType
  ) => {
    try {
      if (isAuthenticated) {
        setLoadingCompletions(true)
        const request_body = {
          generate_and_save_completion_input: {
            input: {
              model: 'text-davinci-003',
              prompt: [prompt],
              max_tokens: parseInt(
                Math.max(parseInt(completionLength || 0), 500) * 1.5
              ),
            },
            input_order: 0, // TODO: Need to handle this order in future
            user_session_id: '22b72df8-cb51-451a-999c-4f5ca30f81a3', // TODO: Need to handle this ID  in future
            output_type: completionType, //'TEXT_COMPLETION' or 'SUMMARY',
          },
        }

        const response = await axios.post(
          process.env.REACT_APP_HUBBLE_API_BASE_URL +
            '/generate_and_save_completion',
          request_body,
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          }
        )

        if (completionType === 'TEXT_COMPLETION') {
          saveToSupabase(
            'hubble_essays',
            request_body,
            response.data,
            user?.email || 'guest'
          )
        } else {
          saveToSupabase(
            'hubble_summaries',
            request_body,
            response.data,
            user?.email || 'guest'
          )
        }

        dispatch(fetchAvailableFreeQuota(await getAccessTokenSilently()))
        getUserCompletions()
        setLoadingCompletions(false)
        setShowInputForm(false)
        return response?.data?.completion?.output?.choices
      }
    } catch (err) {
      setLoadingCompletions(false)
      // console.log('Errror in requesting Essays: ', err)
    }
  }

  // Helper method to get completions through our backend
  const handleGenerateCompletion = async (requestBody) => {
    try {
      if (isAuthenticated) {
        setLoadingCompletions(true)
        const response = await axios.post(
          process.env.REACT_APP_HUBBLE_API_BASE_URL +
            '/generate_and_save_completion',
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          }
        )

        if (
          requestBody?.['general_information']?.['output_type'] ===
          OUTPUT_TYPE.ACADEMIC_ESSAY
        ) {
          saveToSupabase(
            'hubble_essays',
            requestBody,
            response?.data,
            user?.email || 'guest'
          )
        } else if (
          requestBody?.['general_information']?.['output_type'] ===
          OUTPUT_TYPE.SUMMARY
        ) {
          saveToSupabase(
            'hubble_summaries',
            requestBody,
            response.data,
            user?.email || 'guest'
          )
        }
        dispatch(updateAvailableQuotaByValue(-1))
        getUserCompletions()
        setLoadingCompletions(false)
        setShowInputForm(false)
        return response
      }
    } catch (err) {
      setLoadingCompletions(false)
    } finally {
      setLoadingCompletions(false)
    }
  }

  // Get the userdata from our Backend. This primarily holds the stripe related info and the user profile!
  useEffect(() => {
    const getUserDataFromBE = async () => {
      try {
        const accessToken = await getAccessTokenSilently()
        dispatch(fetchUserData(accessToken))
        dispatch(fetchAvailableFreeQuota(accessToken))
        dispatch(fetchGeneratedOutputsCount(accessToken))
        getUserCompletions()
        setIsLoadingUserData(false)
      } catch (err) {
        // console.log(err)
      } finally {
        setIsLoadingUserData(false)
      }
    }

    // TODO: Uncomment the below line and remove the next one after email verification is brought back
    // if (isAuthenticated && user?.email_verified) getUserDataFromBE()
    if (!isLoading && isAuthenticated) getUserDataFromBE()
    else if (!isLoading && !isAuthenticated) setIsLoadingUserData(false)

    //It needs a modification before adding to the list. Else, infinite function calls are happening
  }, [
    isAuthenticated,
    user?.email_verified,
    isLoading,
    dispatch,
    getAccessTokenSilently,
  ])

  useEffect(() => {
    const loadFormDataFromCookies = () => {
      if (cookies?.loadFromCookies) {
        if (cookies?.essayTopic) setEssayTopic(cookies?.essayTopic)
        if (cookies?.essayDetails) setEssayDetails(cookies?.essayDetails)
        if (cookies?.essayLength) setEssayLength(cookies?.essayLength)
        if (cookies?.summaryPrompt) setSummaryPrompt(cookies?.summaryPrompt)

        // Clearing the flag to ensure we are not clearing the form upon every load!
        setCookie('loadFromCookies', false, {
          path: '/',
        })
      }
    }

    if (!isLoading && isAuthenticated) loadFormDataFromCookies()
    // console.log('Loading flag: ', isLoading, isLoadingUserData)
  }, [isLoading, isAuthenticated])

  // TODO: Implement a better loader
  if (isLoading || isLoadingUserData) return <Loader />

  return (
    <div className="flex space-y-0 md:flex-row md:space-x-0 bg-white">
      {/* New Side Nav */}
      <SideNav
        isLoading={isLoading}
        isLoadingUserData={isLoadingUserData}
        accessToken={accessToken}
        isAuthenticated={isAuthenticated}
        user={user}
        toggleBottomModal={toggleBottomModal}
        loginWithRedirect={loginWithRedirect}
        formPreservingAuth={formPreservingAuth}
        takeUserToStripe={takeUserToStripe}
        userData={userData}
        showBottomModal={showBottomModal}
        generatedCompletions={generatedCompletions}
        logout={logout}
        essayTopic={essayTopic}
        essayDetails={essayDetails}
        essayLength={essayLength}
        generatedEssay={generatedEssay}
        setGeneratedEssay={setGeneratedEssay}
        setEssayTopic={setEssayTopic}
        setEssayDetails={setEssayDetails}
        setEssayLength={setEssayLength}
        summaryPrompt={summaryPrompt}
        setSummaryPrompt={setSummaryPrompt}
        generatedSummary={generatedSummary}
        setGeneratedSummary={setGeneratedSummary}
        handleGenerateCompletion={handleGenerateCompletion}
        loadingCompletions={loadingCompletions}
        resendVerificationEmail={resendVerificationEmail}
        setShowInputForm={setShowInputForm}
        showInputForm={showInputForm}
        setSelectedCompletion={setSelectedCompletion}
        selectedCompletion={selectedCompletion}
        deleteCompletion={deleteCompletion}
      />

      {/* Main Body container - Desktop */}
      <main className="md:flex md:flex-col w-full bg-white hidden overflow-y-scroll h-screen">
        {/* {isAuthenticated && !userData?.is_member && (
            <div className="bg-[#F7F7FD] hidden md:block">
              <div className="mx-auto max-w-full py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex flex-wrap items-center justify-between">
                  <div className="flex w-0 flex-1 items-center">
                    <span className="flex rounded-lg bg-indigo-800 p-2">
                      <img
                        src={AnnounceIcon}
                        alt="Callout icon"
                        className="h-6 w-6 text-white"
                      />
                    </span>
                    <div className="ml-3 truncate font-medium text-black-500">
                      <span className="md:hidden">Trial Plan Overview</span>
                      <span className="hidden md:inline">
                        Trial Plan Overview
                      </span>
                      <p className="text-sm font-normal text-gray-500">
                        You have used{' '}
                        {Math.min(
                          process.env.REACT_APP_HUBBLE_FREE_ESSAYS,
                          parseInt(essaysCount)
                        )}{' '}
                        of {process.env.REACT_APP_HUBBLE_FREE_ESSAYS} free
                        essays and summaries.
                      </p>
                    </div>
                  </div>
                  <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                    <button
                      className="mr-2 flex-row items-center inline-flex border-0 py-2 px-4 focus:outline-none rounded-md text-md text-white bg-[#0027B3] hover:bg-[#122362] w-full md:w-fit"
                      onClick={takeUserToStripe}
                    >
                      <img
                        src={UpgradeIcon}
                        alt="Upgrade icon"
                        className="mr-2 text-white"
                      />
                      Upgrade Plan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        {selectedCompletion && (
          <div className="flex px-5 py-2 items-center justify-between w-full bg-white">
            {/* Starting elements in the top section of historical essay view */}
            <div className="flex items-center gap-3">
              {/* Generated ago label */}
              <GeneratedAt selectedCompletion={selectedCompletion} />

              {/* Free Essay Counter for non-paid users */}
              {isAuthenticated && !userData?.is_member && (
                <TopNavUpgradeCallout takeUserToStripe={takeUserToStripe} />
              )}
            </div>

            {/* Ending elements in the top section of historical essay view */}
            <div className="flex items-center">
              {/* Delete Button */}
              {!showInputForm && (
                <button
                  className="border-red-600	flex flex-row justify-center items-center gap-2 py-1 my-0.5 px-4 border rounded-md"
                  onClick={deleteCompletion}
                  ph-attr={DESKTOP_DELETE_OUTPUT_ACTION}
                >
                  <TrashIcon className="text-white stroke-[#CC0033]" />
                  <span className="text-red-600">Delete</span>
                </button>
              )}

              {/* Plagiarism Checker */}
              {/* {isAuthenticated && userData?.is_member && (
                  <PlagiarismCheckerForDesktop />
                )} */}
            </div>
            {/* <div className="flex text-base font-normal text-gray-900 items-center space-x-1"></div> */}
          </div>
        )}

        <Outlet
          context={{
            user,
            userData,
            essayTopic,
            essayDetails,
            essayLength,
            generatedEssay,
            setGeneratedEssay,
            setEssayTopic,
            setEssayDetails,
            setEssayLength,
            summaryPrompt,
            setSummaryPrompt,
            generatedSummary,
            setGeneratedSummary,
            isAuthenticated,
            loginWithRedirect,
            formPreservingAuth,
            accessToken,
            handleGenerateCompletion,
            loadingCompletions,
            resendVerificationEmail,
            takeUserToStripe,
            showInputForm,
            setShowInputForm,
          }}
        />
      </main>
    </div>
  )
}

export default Layout
