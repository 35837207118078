import { COMPLETION_RESPONSE_V1, OUTPUT_TYPE } from '../constants'

const processV1Response = (completion) => {
  let completionTitle = ''
  let completionDetails = ''
  let userInputtedWordCount = ''

  const generatedOutput = completion?.additional_information?.frontend_output
  const inputPrompt = completion?.additional_information?.frontend_prompt

  const newPrompt = 'Summarize the following: ###' //${parse(summaryPrompt)}###'
  const gistLength = 20
  const outputLength = 45

  const essayGist = generatedOutput || ''
  const completionType = completion?.output_type
  const essayTitle =
    completionType === OUTPUT_TYPE.TEXT_COMPLETION
      ? inputPrompt?.substr(18)
      : inputPrompt?.indexOf(newPrompt) === 0
      ? inputPrompt?.substr(newPrompt.length)
      : inputPrompt?.substr(11)

  if (completionType === OUTPUT_TYPE.TEXT_COMPLETION) {
    let param1 = 'Write an essay on:'
    let param2 =
      'Incorporate the following bullet points as details in the essay:'
    let param3 = 'Limit to a word count to '

    const essayTitleStart = inputPrompt?.indexOf(param1)
    const essayDetailsStart = inputPrompt?.indexOf(param2)
    const essayLengthStart = inputPrompt?.indexOf(param3)

    const essayTitleEnd =
      essayDetailsStart === -1
        ? essayLengthStart === -1
          ? inputPrompt?.length - 1
          : essayLengthStart - 1
        : essayDetailsStart

    completionTitle = inputPrompt
      ?.substring(essayTitleStart + param1.length, essayTitleEnd)
      ?.trim()

    // Setting the Essay details content to the form
    if (essayDetailsStart === -1) {
      completionDetails = ''
    } else {
      const essayDetailsEnd =
        essayLengthStart === -1 ? inputPrompt?.length : essayLengthStart
      completionDetails = inputPrompt
        ?.substring(essayDetailsStart + param2.length, essayDetailsEnd)
        ?.trim()
    }

    // Setting the Essay length
    if (essayLengthStart === -1) userInputtedWordCount = ''
    else {
      const promptPieces = inputPrompt?.split(' ')
      const essayLength = parseInt(promptPieces[promptPieces?.length - 1])
      userInputtedWordCount = essayLength
    }
  } else {
    let param1 = 'Summarize: '

    if (inputPrompt?.indexOf(newPrompt) === 0) {
      const summaryPieces = inputPrompt?.split(newPrompt)
      const extractedString = summaryPieces[summaryPieces?.length - 1]
      completionTitle = extractedString?.substring(
        0,
        extractedString?.length - 3
      )
    } else {
      const summaryPieces = inputPrompt?.split(param1)
      completionTitle = summaryPieces[summaryPieces?.length - 1]
    }
  }
  return {
    completionTitle,
    completionDetails,
    wordCount: userInputtedWordCount,
  }
}

const processV2Response = (completion) => {
  // console.log(completion)
  const userPromptData = JSON.parse(
    completion?.additional_information?.frontend_prompt
  )

  // TODO: Once we have a better support for title at the top level of the response object, we can move the conditional processing to here.
  let generatedContentTitle = ''
  let generatedContentBody = completion?.output
  const completionType = userPromptData?.general_information?.output_type

  if (completionType === OUTPUT_TYPE.ACADEMIC_ESSAY) {
    generatedContentTitle =
      completion?.title || userPromptData?.specific_information?.topic
  } else if (completionType === OUTPUT_TYPE.SUMMARY) {
    generatedContentTitle =
      completion?.title ||
      userPromptData?.specific_information?.text_to_summarize
  }

  return {
    completionTitle: generatedContentTitle,
    completionDetails: generatedContentBody,
  }
}

export const processCompletionData = (completion) => {
  if (completion?.response_version === COMPLETION_RESPONSE_V1) {
    return processV1Response(completion)
  } else {
    return processV2Response(completion)
  }
}
