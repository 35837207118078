import React from 'react'
import { HiChevronUp, HiUserCircle } from 'react-icons/hi'
import { BsFillMegaphoneFill } from 'react-icons/bs'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import PaymentIcon from '../../assets/icons/PaymentIcon.svg'
import LogoutIcon from '../../assets/icons/LogoutIcon.svg'
import {
  DESKTOP_SIDENAV_LOGIN_CLICK,
  DESKTOP_SIDENAV_LOGOUT_CLICK,
  DESKTOP_SIDENAV_MYACCOUNT_CLICK,
  DESKTOP_SIDENAV_SIGNUP_CLICK,
  DESKTOP_SIDENAV_VIEWPAYMENTS_CLICK,
} from '../../constants/posthog'
import { getUserProfileData } from '../../features/user/userSlice'
import { useSelector } from 'react-redux'

const SideNavFooterForDesktop = ({
  isAuthenticated,
  loginWithRedirect,
  formPreservingAuth,
  user,
  takeUserToStripeDashboard,
  logout,
}) => {
  const userData = useSelector(getUserProfileData)

  if (!isAuthenticated)
    return (
      <div className="hidden absolute bottom-0 md:sticky w-full bg-white gap-2 md:flex md:flex-col-2 justify-center py-2 border border-b md:order-4">
        {/* Login Button */}
        <div>
          <button
            className="flex px-2 py-1.5 text-base border border-[#0027B3] font-normal focus:outline-none rounded text-white bg-[#0027B3] hover:bg-[#122362] w-full justify-center"
            onClick={() => {
              formPreservingAuth()
              loginWithRedirect()
            }}
            ph-attr={DESKTOP_SIDENAV_LOGIN_CLICK}
          >
            {/*<BiFace className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400" />*/}
            <span className="px-4">Login</span>
          </button>
        </div>
        {/* Sign up button */}
        <div>
          <button
            className="flex px-2 py-1.5 rounded text-base font-normal text-gray-500 bg-white border w-full justify-center"
            onClick={() => {
              formPreservingAuth()
              loginWithRedirect({ screen_hint: 'signup' })
            }}
            ph-attr={DESKTOP_SIDENAV_SIGNUP_CLICK}
          >
            {/*<BiEnvelope className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400" />*/}
            <span className="px-4 whitespace-nowrap">Sign Up</span>
          </button>
        </div>
      </div>
    )
  else
    return (
      <div>
        <span className="text-sm font-semibold flex">
          <div className="ml-4 mt-0.5 text-[#0027B3]">
            <BsFillMegaphoneFill />
          </div>
          <a
            className="ml-3 text-[#0027B3]"
            href="https://roadmap.hubble.ai/t/updates"
          >
            View Product Updates
          </a>
        </span>
        <div className="sticky pt-4 bottom-0 bg-white hidden md:flex">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <div
                className="hidden md:sticky md:flex md:flex-row w-full bg-white gap-2 flex-col-2 justify-between pt-2 pb-2 border-t md:order-4 px-4 items-center"
                ph-attr={DESKTOP_SIDENAV_MYACCOUNT_CLICK}
              >
                <span className="flex items-center space-x-2">
                  {user?.picture ? (
                    <img
                      src={user?.picture}
                      alt="Avatar"
                      className="rounded-full w-6 h-6"
                    />
                  ) : (
                    <HiUserCircle className="rounded-full w-6 h-6" />
                  )}
                  {/* <label>{user?.nickname}</label> */}
                  <label className="text-[#575757] font-semibold text-normal pl-2">
                    My Account
                    <p className="text-gray-500 text-sm font-normal">
                      {' '}
                      {user.email
                        ? user.email < 16
                          ? user.email
                          : user.email.substring(0, 16) + '...'
                        : '-'}
                    </p>
                  </label>
                </span>

                <HiChevronUp className="text-bold text-xl" />
              </div>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="mb-2 rounded-md font-normal md:w-64 lg:w-80 border mt-2 bg-white shadow-sm text-gray-700 focus:outline-none flex flex-col divide-y"
                sideOffset={0}
              >
                <DropdownMenu.Item>
                  <div className="flex flex-row items-center p-3 gap-2">
                    <label className="ml-2 text-sm font-normal text-black">
                      {user?.email}
                    </label>
                  </div>
                </DropdownMenu.Item>
                {userData?.is_member && (
                  <DropdownMenu.Item>
                    <div
                      className="flex flex-row items-center p-3 gap-2"
                      onClick={takeUserToStripeDashboard}
                      ph-attr={DESKTOP_SIDENAV_VIEWPAYMENTS_CLICK}
                    >
                      <img src={PaymentIcon} alt="Payment Icon" />
                      <label className="ml-2 text-sm font-light text-black">
                        Payment
                      </label>
                    </div>
                  </DropdownMenu.Item>
                )}

                <DropdownMenu.Item className="DropdownMenuItem">
                  <div
                    className="flex flex-row items-center p-3 gap-2"
                    onClick={() => {
                      logout({ returnTo: window.location.origin })
                    }}
                    ph-attr={DESKTOP_SIDENAV_LOGOUT_CLICK}
                  >
                    <img className="ml-2" src={LogoutIcon} alt="Logout Icon" />
                    <label className="ml-1 text-sm font-semibold text-black">
                      Logout
                    </label>
                  </div>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </div>
      </div>
    )
}

export default SideNavFooterForDesktop
