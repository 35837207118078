import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { HiChevronUp, HiPlus } from 'react-icons/hi'
import { MdKeyboardArrowRight, MdOutlineClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import EssayIcon from '../../assets/icons/EssayIcon.svg'
import SummaryIcon from '../../assets/icons/SummaryIcon.svg'
import {
  MOBILE_SIDENAV_NEW_DOCUMENT_CLICK,
  MOBILE_SIDENAV_NEW_ESSAY_CLICK,
  MOBILE_SIDENAV_NEW_SUMMARY_CLICK,
} from '../../constants/posthog'

const SideNavActionButtonForMobile = ({
  toggleShowForm,
  clearEssayForm,
  clearSummaryForm,
  user,
  takeUserToStripe,
  takeUserToStripeDashboard,
  logout,
}) => {
  const navigate = useNavigate()
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <div className="flex md:hidden justify-center fixed bottom-0 bg-white py-4 w-full">
      <div
        className="flex flex-row items-center border justify-center mx-4 rounded divide-x w-full"
        ph-attr={MOBILE_SIDENAV_NEW_DOCUMENT_CLICK}
      >
        <button
          className="flex flex-row items-center py-2 px-3 gap-3 grow justify-start"
          onClick={openModal}
        >
          <HiPlus className="cursor-pointer w-5 h-5" />
          <label className="cursor-pointer">New Document</label>
        </button>

        <HiChevronUp
          className="flex flex-row justify-center items-center gap-1.5 py-2 px-2.5 w-12 h-12 cursor-pointer focus:outline-none"
          onClick={openModal}
        />
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed bottom-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen transform overflow-hidden bg-[#FBFBFA] p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-black"
                  >
                    <div className="flex pb-2 text-normal font-medium flex-row justify-between items-center">
                      <h2>Create a New Document</h2>
                      <MdOutlineClose
                        onClick={closeModal}
                        className="w-5 h-5 text-[#9B9B9B]"
                      />
                    </div>
                  </Dialog.Title>

                  {/* Modal Body */}
                  <div className="mt-4 mb-4 flex flex-col space-y-4">
                    {/* Choose an Option */}
                    <div className="border rounded-lg flex flex-col divide-y w-full bg-white">
                      {/* TODO: Change to Link element and test the flow */}
                      <button
                        className="flex flex-row items-center p-4 gap-1"
                        onClick={() => {
                          clearEssayForm()
                          closeModal()
                          toggleShowForm()
                          navigate('/essays')
                        }}
                        ph-attr={MOBILE_SIDENAV_NEW_ESSAY_CLICK}
                      >
                        <img src={EssayIcon} alt="Essay Icon" className="w-5" />
                        <div className="ml-4 grow flex flex-col items-start">
                          <h1 className="font-bold text-lg">Essay</h1>
                          <p className="text-sm text-[#575757]"></p>
                        </div>
                        <MdKeyboardArrowRight className="w-6 h-6 sticky right-0" />
                      </button>

                      {/* TODO: Change to Link element and test the flow */}
                      <button
                        className="flex flex-row items-center p-4 gap-1"
                        onClick={() => {
                          clearSummaryForm()
                          closeModal()
                          toggleShowForm()
                          navigate('/summarize')
                        }}
                        ph-attr={MOBILE_SIDENAV_NEW_SUMMARY_CLICK}
                      >
                        <img
                          src={SummaryIcon}
                          alt="Summary Icon"
                          className="w-5"
                        />
                        <div className="ml-4 grow flex flex-col items-start">
                          <h1 className="font-bold text-lg">Summary</h1>
                          <p className="text-sm text-[#575757]"></p>
                        </div>
                        <MdKeyboardArrowRight className="w-6 h-6" />
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default SideNavActionButtonForMobile
