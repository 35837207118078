import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import { CookiesProvider } from 'react-cookie'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { fetchUserData } from './features/user/userSlice'

import posthog from 'posthog-js'
import Counter from './features/counter/Counter'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

posthog.init('phc_t0EVCgp3CUltX0fkb0WeQwmylMbhqVUBzGarGsz2Q7A', {
  api_host: 'https://app.posthog.com',
})

// Sentry.init({
//   dsn: 'https://16c6e4a9a62d4e20ae16426f8790ba28@o4504022406201344.ingest.sentry.io/4504477568008192',
//   environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
//   integrations: [
//     new BrowserTracing(),
//     new Sentry.Replay({
//       // Additional SDK configuration goes in here, for example:
//       maskAllText: true,
//       blockAllMedia: true,
//     }),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,

//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1,

//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
//   attachStacktrace: true,
// })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <CookiesProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CookiesProvider>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
