import React from 'react'
import { HiPlus, HiChevronUp } from 'react-icons/hi'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Link } from 'react-router-dom'
import SummaryIcon from '../../assets/icons/SummaryIcon.svg'
import EssayIcon from '../../assets/icons/EssayIcon.svg'
import {
  DESKTOP_SIDENAV_NEW_ESSAY_CLICK,
  DESKTOP_SIDENAV_NEW_SUMMARY_CLICK,
} from '../../constants/posthog'

const SideNavActionButtonWithPopup = ({
  clearEssayForm,
  clearSummaryForm,
  setSelectedCompletion,
}) => {
  return (
    <div className="sticky bottom-0 bg-white pt-4 pb-4 hidden md:flex">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <div className="flex flex-row items-center border justify-center mx-4 rounded divide-x w-full">
            <button className="flex flex-row items-center py-2 px-3 gap-1.5 grow justify-center">
              <HiPlus className="cursor-pointer w-4 h-4 text-[#575757]" />
              <label className="cursor-pointer font-medium text-sm px-3">
                New Document
              </label>
            </button>

            <HiChevronUp className="text-[#9B9B9B] flex flex-row justify-center items-center gap-1.5 py-2 px-2.5 w-10 h-10 cursor-pointer" />
          </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="rounded-md font-normal  md:w-56 lg:w-48 border my-2 bg-white text-black focus:outline-none flex flex-col divide-y"
            sideOffset={0}
            side="top"
          >
            <DropdownMenu.Item>
              <Link
                className="py-3 text-sm px-4 flex w-screen justify-center md:w-56 lg:w-48"
                to="/"
                onClick={() => {
                  setSelectedCompletion()
                  clearEssayForm()
                }}
                ph-attr={DESKTOP_SIDENAV_NEW_ESSAY_CLICK}
              >
                <img src={EssayIcon} alt="Essay icon" className="w-5" />
                <h1 className="ml-3 font-semibold text-md">Essay</h1>
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item>
              <Link
                className="py-3 text-sm px-4 flex w-screen justify-center md:w-56 lg:w-48"
                to="/summarize"
                onClick={() => {
                  setSelectedCompletion()
                  clearSummaryForm()
                }}
                ph-attr={DESKTOP_SIDENAV_NEW_SUMMARY_CLICK}
              >
                <img src={SummaryIcon} alt="Summary icon" className="w-5" />
                <h1 className="ml-3 font-semibold text-md">Summary</h1>
              </Link>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  )
}

export default SideNavActionButtonWithPopup
