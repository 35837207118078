import React from 'react'
import UpgradeIcon from '../../assets/icons/UpgradeIcon.svg'
import EssayAndSummaryIcon from '../../assets/icons/EssayAndSummaryIcon.svg'
import { getAvailableQuota } from '../../features/user/userSlice'
import { useSelector } from 'react-redux'

const SidenavUpgradeCallout = ({ takeUserToStripe, bgColor }) => {
  const availableFreeEssaysQuota = useSelector(getAvailableQuota)

  return (
    <div
      id="dropdown-cta"
      className={`rounded-lg p-5 pt-4 md:order-3 order-4 bg-${
        bgColor ?? '[#FBFBFA]'
      }`}
      role="alert"
    >
      <div id="dropdown-cta" className="md:order-3 order-4" role="alert">
        <div className="flex items-center mb-1 pb-1">
          <span className=" text-black text-sm font-bold rounded h-fit">
            Trial Plan
          </span>
        </div>
        <div className="flex flex-row border-b pb-3">
          <img src={EssayAndSummaryIcon} alt="Announce Icon" />

          <p className="ml-2 flex text-sm font-normal text-gray-500">
            {Math.min(
              process.env.REACT_APP_HUBBLE_FREE_ESSAYS,
              process.env.REACT_APP_HUBBLE_FREE_ESSAYS -
                availableFreeEssaysQuota
            )}{' '}
            of {process.env.REACT_APP_HUBBLE_FREE_ESSAYS} free documents used.
          </p>
        </div>
      </div>
      <p className="my-3 text-sm text-black-500">
        Upgrade your account to write unlimited content.
      </p>

      <button
        className="flex flex-row justify-center text-sm text-black-500 font-medium text-md items-center rounded-md w-full py-2 bg-[#0027B3] text-white"
        onClick={takeUserToStripe}
      >
        <img src={UpgradeIcon} alt="Upgrade Icon" className="mr-2" />
        Upgrade Plan
      </button>
    </div>
  )
}

export default SidenavUpgradeCallout
