// Desktop elements
export const DESKTOP_SIDENAV_LOGIN_CLICK = 'desktopSidenavLoginClick'
export const DESKTOP_SIDENAV_SIGNUP_CLICK = 'desktopSidenavSignupClick'
export const DESKTOP_SIDENAV_MYACCOUNT_CLICK = 'desktopSidenavMyAccountClick'
export const DESKTOP_SIDENAV_VIEWPAYMENTS_CLICK = 'desktopViewPaymentsClick'
export const DESKTOP_SIDENAV_LOGOUT_CLICK = 'desktopLogoutClick'
export const DESKTOP_SIDENAV_NEW_DOCUMENT_CLICK =
  'desktopSidenavNewDocumentClick'
export const DESKTOP_SIDENAV_NEW_ESSAY_CLICK = 'desktopSidenavNewEssayClick'
export const DESKTOP_SIDENAV_NEW_SUMMARY_CLICK = 'desktopSidenavNewSummaryClick'
export const DESKTOP_TOPNAV_UPGRADE_CLICK = 'desktopTopnavUpgradeClick'
export const DESKTOP_SIDENAV_UPGRADE_CLICK = 'desktopSidenavUpgradeClick'
export const DESKTOP_HISTORICAL_ITEM_CALLOUT_CLICK =
  'desktopHistoricalItemCalloutClick'
export const DESKTOP_DELETE_OUTPUT_ACTION = 'desktopDeleteOutputAction'

// Mobile elements
export const MOBILE_SIDENAV_LOGIN_CLICK = 'mobileSidenavLoginClick'
export const MOBILE_SIDENAV_SIGNUP_CLICK = 'mobileSidenavSignupClick'
export const MOBILE_USER_AVATAR_CLICK = 'mobileSidenavMyAccountClick'
export const MOBILE_MY_ACCOUNT_MODAL_CLOSE_CLICK =
  'mobileMyAccountModalCloseClick'
export const MOBILE_SIDENAV_VIEWPAYMENTS_CLICK = 'mobileViewPaymentsClick'
export const MOBILE_SIDENAV_LOGOUT_CLICK = 'mobileLogoutClick'
export const MOBILE_SIDENAV_NEW_DOCUMENT_CLICK =
  'desktopSidenavNewDocumentClick'
export const MOBILE_SIDENAV_NEW_ESSAY_CLICK = 'mobileSidenavNewEssayClick'
export const MOBILE_SIDENAV_NEW_SUMMARY_CLICK = 'mobileSidenavNewSummaryClick'
export const MOBILE_SIDENAV_UPGRADE_CLICK = 'mobileSidenavUpgradeClick'
export const Mobile_THREEDOT_POPUP_UPGRADE_CLICK =
  'mobileThreedotPopupUpgradeClick'
export const MOBILE_HISTORICAL_ITEM_CALLOUT_CLICK =
  'mobileHistoricalItemCalloutClick'
export const MOBILE_DELETE_OUTPUT_ACTION = 'mobileDeleteOutputAction'

// Common Elements
export const COMMON_SIDENAV_HISTORICAL_ITEM_CLICK =
  'commonSidenavHistoricalItemClick'
export const COMMON_TABS_ESSAY_CLICK = 'desktopTabsEssayClick'
export const COMMON_TABS_SUMMARY_CLICK = 'desktopTabsSummaryClick'
export const COMMON_GENERATE_ESSAY_CLICK = 'desktopGenerateEssayClick'
export const COMMON_GENERATE_SUMMARY_CLICK = 'desktopGenerateSummaryClick'
export const COMMON_CLEAR_FORM_CLICK = 'desktopClearFormClick'
export const COMMON_VIEW_SAMPLE_CLICK = 'desktopViewSampleClick'
export const COMMON_COPY_ESSAY_TO_CLIPBOARD_CLICK =
  'commonCopyEssayToClipboardClick'
export const COMMON_COPY_SUMMARY_TO_CLIPBOARD_CLICK =
  'commonCopySummaryToClipboardClick'
export const COMMON_MAIN_FORM_SIGNUP_CLICK = 'commonMainFormSignupClick'
export const COMMON_MAIN_FORM_UPGRADE_CLICK = 'commonMainFormUpgradeClick'
