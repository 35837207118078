import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  COMPLETION_RESPONSE_V1,
  OUTPUT_TYPE,
} from '../../constants/contractEnums'
import {
  COMMON_SIDENAV_HISTORICAL_ITEM_CLICK,
  MOBILE_DELETE_OUTPUT_ACTION,
} from '../../constants/posthog'
import parse from 'html-react-parser'
import { useLongPress } from 'use-long-press'
import { EssayIcon, TrashIcon, SummaryIcon } from '../../assets/icons'
import GeneratedAt from './GeneratedAt'
import { processCompletionData } from '../../utils/generatedCompletionHelpers'
import { Dialog, Transition } from '@headlessui/react'
import { MdOutlineClose } from 'react-icons/md'

const GeneratedCompletionItemV2 = ({
  completion,
  user,
  setEssayTopic,
  setEssayDetails,
  setEssayLength,
  setGeneratedEssay,
  setSummaryPrompt,
  setGeneratedSummary,
  toggleShowForm,
  setShowInputForm,
  setSelectedCompletion,
  selectedCompletion,
  deleteCompletion,
}) => {
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    if (selectedCompletion) {
      const { completionTitle } = processCompletionData(selectedCompletion)
      setModalTitle(completionTitle)
    }
  }, [selectedCompletion])

  const [enabled, setEnabled] = useState(true)

  const callback = useCallback((event) => {
    setSelectedCompletion(completion)
    openModal()
  }, [])

  const bind = useLongPress(enabled ? callback : null, {
    // onStart: (event) => console.log('Press started'),
    // onFinish: (event) => console.log('Long press finished'),
    // onCancel: (event) => console.log('Press cancelled'),
    // onMove: (event) => console.log('Detected mouse or touch movement'),
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: 'touch', // Detect only touch events to ensure long press is handled only on mobile devices!
  })

  if (!completion?.additional_information?.frontend_output) return null

  const userPromptData = JSON.parse(
    completion?.additional_information?.frontend_prompt
  )

  // TODO: Once we have a better support for title at the top level of the response object, we can move the conditional processing to here.
  let generatedContentTitle = ''
  let generatedContentBody = completion?.output
  const completionType = userPromptData?.general_information?.output_type

  if (completionType === OUTPUT_TYPE.ACADEMIC_ESSAY) {
    generatedContentTitle =
      completion?.title || userPromptData?.specific_information?.topic
  } else if (completionType === OUTPUT_TYPE.SUMMARY) {
    generatedContentTitle =
      completion?.title ||
      userPromptData?.specific_information?.text_to_summarize
  }

  const newPrompt = 'Summarize the following: ###'
  const gistLength = 20
  const outputLength = 45

  const handleHistoricalItemClick = () => {
    try {
      toggleShowForm()

      if (completionType === OUTPUT_TYPE.ACADEMIC_ESSAY) {
        setEssayTopic(userPromptData?.specific_information?.topic?.trim())
        setEssayDetails(
          parse(
            userPromptData?.specific_information?.supplemental_content
              ?.join(' ')
              ?.trim()
          )
        )
        setEssayLength(userPromptData?.general_information?.desired_word_count)
        // console.log('Completion: ', completion?.output?.choices)
        setGeneratedEssay({ completion: completion?.output })
        setShowInputForm(false)
        navigate('/essays')
      } else if (completionType === OUTPUT_TYPE.SUMMARY) {
        // console.log(`Completion [${completionType}]: `, userPromptData)

        let param1 = 'Summarize: '
        if (generatedContentTitle?.indexOf(newPrompt) === 0) {
          const summaryPieces = generatedContentTitle?.split(newPrompt)
          const extractedString = summaryPieces[summaryPieces?.length - 1]
          setSummaryPrompt(extractedString)
          setGeneratedSummary({ completion: completion?.output })
          setShowInputForm(false)
          navigate('/summarize')
        } else {
          const summaryPieces = generatedContentTitle?.split(param1)
          const extractedString = summaryPieces[summaryPieces?.length - 1]
          setSummaryPrompt(extractedString)
          setGeneratedSummary({ completion: completion?.output })
          setShowInputForm(false)
          navigate('/summarize')
        }
      }

      setSelectedCompletion(completion)
    } catch (err) {
      // console.log(err)
    }
  }

  // Adding this conditional logic to ensure we are not showing any other output types at the moment.
  // TODO: Remove or update this logic based on the updates we make to the UI
  if (
    completionType !== OUTPUT_TYPE.ACADEMIC_ESSAY &&
    completionType !== OUTPUT_TYPE.SUMMARY
  )
    return null

  return (
    <>
      <div
        className="border-b md:border-0 lg:border-0 hover:bg-[#F7F7FD] hover:rounded-md hover:mx-2 mx-2 md:px-2 lg:px-2 px-5 cursor-pointer py-3"
        onClick={handleHistoricalItemClick}
        key={completion?.id}
        ph-attr={COMMON_SIDENAV_HISTORICAL_ITEM_CLICK}
        {...bind()}
      >
        {/* TODO: Improve the display of title */}
        <h1 className="font-semibold text-md flex flex-row items-center space-x-1 pb-1 cursor-pointer">
          {completionType === OUTPUT_TYPE.TEXT_COMPLETION ||
          completionType === OUTPUT_TYPE.ACADEMIC_ESSAY ? (
            <EssayIcon className="mr-3 w-4 h-4" />
          ) : (
            <SummaryIcon className="mr-3 w-4 h-4" />
          )}

          <span className="text-md font-semibold cursor-pointer">
            {generatedContentTitle?.length <= gistLength
              ? generatedContentTitle
                ? generatedContentTitle
                : 'No Prompt'
              : generatedContentTitle?.substr(0, gistLength) + '...'}
          </span>
        </h1>

        <p className="text-sm text-[#575757] cursor-pointer flex space-x-1 items-center">
          <span className="grow">
            {generatedContentBody?.length <= outputLength
              ? generatedContentBody || '<No Essay Available>'
              : generatedContentBody?.substr(0, outputLength) + '...'}
          </span>
        </p>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed bottom-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen transform overflow-hidden bg-[#FBFBFA] p-6 text-left align-middle transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 border-b"
                  >
                    <div className="pb-4 flex flex-row justify-between items-center">
                      <h2 className="font-medium text-md">Actions</h2>
                      <button
                        onClick={closeModal}
                        // ph-attr={MOBILE_MY_ACCOUNT_MODAL_CLOSE_CLICK}
                      >
                        <MdOutlineClose className="w-5 h-5 text-[#9B9B9B]" />
                      </button>
                    </div>
                  </Dialog.Title>

                  {/* Modal Body */}
                  <div className="overflow-hidden rounded-lg ">
                    <div className="py-4 flex flex-col justify-center gap-6">
                      {/* Essay related information */}
                      <div className="flex flex-row items-center">
                        {selectedCompletion?.output_type ===
                          OUTPUT_TYPE.ACADEMIC_ESSAY ||
                        selectedCompletion?.output_type ===
                          OUTPUT_TYPE.TEXT_COMPLETION ? (
                          <EssayIcon className="mr-3 w-4 h-4" />
                        ) : (
                          <SummaryIcon className="mr-3 w-4 h-4" />
                        )}

                        <div className="flex flex-col">
                          <h1 className="px-2 -mb-1 text-lg font-semibold">
                            {modalTitle?.length <= 40
                              ? modalTitle
                                ? modalTitle
                                : 'No Prompt'
                              : modalTitle?.substr(0, 40) + '...'}
                          </h1>
                          <GeneratedAt
                            selectedCompletion={selectedCompletion}
                          />
                        </div>
                      </div>

                      {/* Action Buttons */}
                      <div className="flex flex-col w-full rounded-md divide-y border bg-white">
                        <div
                          className="flex flex-row items-center p-4 gap-2"
                          onClick={() => {
                            deleteCompletion()
                            closeModal()
                          }}
                          ph-attr={MOBILE_DELETE_OUTPUT_ACTION}
                        >
                          <TrashIcon className="stroke-[#CC0033]" />
                          <span className="ml-2 text-red-500">
                            Delete document
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

const GeneratedCompletionItemV1 = ({
  completion,
  user,
  setEssayTopic,
  setEssayDetails,
  setEssayLength,
  setGeneratedEssay,
  setSummaryPrompt,
  setGeneratedSummary,
  toggleShowForm,
  setShowInputForm,
  setSelectedCompletion,
  selectedCompletion,
  deleteCompletion,
}) => {
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    if (selectedCompletion) {
      const { completionTitle } = processCompletionData(selectedCompletion)
      setModalTitle(completionTitle)
    }
  }, [selectedCompletion])

  const [enabled, setEnabled] = useState(true)

  const callback = useCallback((event) => {
    setSelectedCompletion(completion)
    openModal()
  }, [])

  const bind = useLongPress(enabled ? callback : null, {
    // onStart: (event) => console.log('Press started'),
    // onFinish: (event) => console.log('Long press finished'),
    // onCancel: (event) => console.log('Press cancelled'),
    // onMove: (event) => console.log('Detected mouse or touch movement'),
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: 'touch', // Detect only touch events to ensure long press is handled only on mobile devices!
  })

  const generatedOutput = completion?.additional_information?.frontend_output
  const inputPrompt = completion?.additional_information?.frontend_prompt

  const newPrompt = 'Summarize the following: ###' //${parse(summaryPrompt)}###'
  const gistLength = 20
  const outputLength = 45

  const essayGist = generatedOutput || ''
  const completionType = completion?.output_type
  const essayTitle =
    completionType === 'TEXT_COMPLETION'
      ? inputPrompt?.substr(18)
      : inputPrompt?.indexOf(newPrompt) === 0
      ? inputPrompt?.substr(newPrompt.length)
      : inputPrompt?.substr(11)

  const handleHistoricalItemClick = () => {
    // console.log('Completion: ', completion)
    toggleShowForm()
    if (completionType === 'TEXT_COMPLETION') {
      let param1 = 'Write an essay on:'
      let param2 =
        'Incorporate the following bullet points as details in the essay:'
      let param3 = 'Limit to a word count to '

      const essayTitleStart = inputPrompt?.indexOf(param1)
      const essayDetailsStart = inputPrompt?.indexOf(param2)
      const essayLengthStart = inputPrompt?.indexOf(param3)

      const essayTitleEnd =
        essayDetailsStart === -1
          ? essayLengthStart === -1
            ? inputPrompt?.length - 1
            : essayLengthStart - 1
          : essayDetailsStart

      setEssayTopic(
        inputPrompt
          ?.substring(essayTitleStart + param1.length, essayTitleEnd)
          ?.trim()
      )

      // Setting the Essay details content to the form
      if (essayDetailsStart === -1) {
        setEssayDetails('')
      } else {
        const essayDetailsEnd =
          essayLengthStart === -1 ? inputPrompt?.length : essayLengthStart

        setEssayDetails(
          inputPrompt
            ?.substring(essayDetailsStart + param2.length, essayDetailsEnd)
            ?.trim()
        )
      }

      // Setting the Essay length
      if (essayLengthStart === -1) setEssayLength('')
      else {
        const promptPieces = inputPrompt?.split(' ')
        const essayLength = parseInt(promptPieces[promptPieces?.length - 1])
        setEssayLength(essayLength)
      }

      setGeneratedEssay({ completion: generatedOutput })
      setShowInputForm(false)
      navigate('/essays')
    } else {
      let param1 = 'Summarize: '
      // console.log('Input prompt:', inputPrompt)
      if (inputPrompt?.indexOf(newPrompt) === 0) {
        const summaryPieces = inputPrompt?.split(newPrompt)
        const extractedString = summaryPieces[summaryPieces?.length - 1]
        setSummaryPrompt(
          extractedString?.substring(0, extractedString?.length - 3)
        )
        setGeneratedSummary({ completion: generatedOutput })
        setShowInputForm(false)
        navigate('/summarize')
      } else {
        const summaryPieces = inputPrompt?.split(param1)
        setSummaryPrompt(summaryPieces[summaryPieces?.length - 1])
        setGeneratedSummary({ completion: generatedOutput })
        setShowInputForm(false)
        navigate('/summarize')
      }
    }

    setSelectedCompletion(completion)
  }

  // }

  return (
    <>
      <div
        className="border-b md:border-0 lg:border-0 hover:bg-[#F7F7FD] hover:rounded-md hover:mx-2 mx-2 md:px-2 lg:px-2 px-5 cursor-pointer py-3"
        onClick={handleHistoricalItemClick}
        key={completion?.id}
        ph-attr={COMMON_SIDENAV_HISTORICAL_ITEM_CLICK}
        {...bind()}
      >
        {/* TODO: Improve the display of title */}
        <h1 className="font-semibold text-md flex flex-row items-center space-x-1 pb-1 cursor-pointer">
          {completion?.output_type === OUTPUT_TYPE.TEXT_COMPLETION ||
          completion?.output_type === OUTPUT_TYPE.ACADEMIC_ESSAY ? (
            <EssayIcon className="mr-3 w-4 h-4" />
          ) : (
            <SummaryIcon className="mr-3 w-4 h-4" />
          )}

          {/*} <IoMdDocument className="text-[#0027B3]  cursor-pointer" />*/}
          <span className="text-md font-semibold cursor-pointer">
            {essayTitle?.length <= gistLength
              ? essayTitle
                ? essayTitle
                : 'No Prompt'
              : essayTitle?.substr(0, gistLength) + '...'}
          </span>
        </h1>
        <p className="text-sm text-[#575757] cursor-pointer flex space-x-1 items-center">
          <span className="grow">
            {essayGist?.length <= outputLength
              ? essayGist || '<No Essay Available>'
              : essayGist?.substr(0, outputLength) + '...'}
          </span>
        </p>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed bottom-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen transform overflow-hidden bg-[#FBFBFA] p-6 text-left align-middle transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 border-b"
                  >
                    <div className="pb-4 flex flex-row justify-between items-center px-4">
                      <h2 className="font-medium text-md">Actions</h2>
                      <button
                        onClick={closeModal}
                        // ph-attr={MOBILE_MY_ACCOUNT_MODAL_CLOSE_CLICK}
                      >
                        <MdOutlineClose className="w-5 h-5 text-[#9B9B9B]" />
                      </button>
                    </div>
                  </Dialog.Title>

                  {/* Modal Body */}
                  <div className="overflow-hidden rounded-lg ">
                    <div className="p-4 flex flex-col justify-center gap-6">
                      {/* Essay related information */}
                      <div className="flex flex-row items-center">
                        {selectedCompletion?.output_type ===
                          OUTPUT_TYPE.ACADEMIC_ESSAY ||
                        selectedCompletion?.output_type ===
                          OUTPUT_TYPE.TEXT_COMPLETION ? (
                          <EssayIcon className="mr-3 w-4 h-4" />
                        ) : (
                          <SummaryIcon className="mr-3 w-4 h-4" />
                        )}

                        <div className="flex flex-col">
                          <h1 className="px-2 -mb-1 text-lg">
                            {modalTitle?.length <= 40
                              ? modalTitle
                                ? modalTitle
                                : 'No Prompt'
                              : modalTitle?.substr(0, 40) + '...'}
                          </h1>

                          <GeneratedAt
                            selectedCompletion={selectedCompletion}
                          />
                        </div>
                      </div>

                      {/* Action Buttons */}
                      <div className="flex flex-col w-full rounded-md divide-y border bg-white">
                        <div
                          className="flex flex-row items-center p-4 gap-2"
                          onClick={() => {
                            deleteCompletion()
                            closeModal()
                          }}
                          ph-attr={MOBILE_DELETE_OUTPUT_ACTION}
                        >
                          <TrashIcon className="stroke-[#CC0033]" />
                          <span className="ml-2 text-red-500">
                            Delete document
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

const GeneratedCompletionsComponent = ({
  generatedCompletions,
  toggleShowForm,
  setEssayTopic,
  setEssayDetails,
  setEssayLength,
  setGeneratedEssay,
  setSummaryPrompt,
  setGeneratedSummary,
  setShowInputForm,
  setSelectedCompletion,
  selectedCompletion,
  deleteCompletion,
}) => {
  // console.log('SC: ', selectedCompletion)
  return (
    <div className="flex flex-col grow overflow-y-scroll">
      {generatedCompletions?.map((completion) => {
        return completion?.response_version === COMPLETION_RESPONSE_V1 ? (
          <GeneratedCompletionItemV1
            completion={completion}
            toggleShowForm={toggleShowForm}
            setEssayTopic={setEssayTopic}
            setEssayDetails={setEssayDetails}
            setEssayLength={setEssayLength}
            setGeneratedEssay={setGeneratedEssay}
            setSummaryPrompt={setSummaryPrompt}
            setGeneratedSummary={setGeneratedSummary}
            setShowInputForm={setShowInputForm}
            setSelectedCompletion={setSelectedCompletion}
            selectedCompletion={selectedCompletion}
            deleteCompletion={deleteCompletion}
          />
        ) : (
          <GeneratedCompletionItemV2
            completion={completion}
            toggleShowForm={toggleShowForm}
            setEssayTopic={setEssayTopic}
            setEssayDetails={setEssayDetails}
            setEssayLength={setEssayLength}
            setGeneratedEssay={setGeneratedEssay}
            setSummaryPrompt={setSummaryPrompt}
            setGeneratedSummary={setGeneratedSummary}
            setShowInputForm={setShowInputForm}
            setSelectedCompletion={setSelectedCompletion}
            selectedCompletion={selectedCompletion}
            deleteCompletion={deleteCompletion}
          />
        )
      })}
    </div>
  )
}

export default GeneratedCompletionsComponent
