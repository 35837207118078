import { configureStore } from '@reduxjs/toolkit'
import userRequestsReducer from '../features/userRequestsSlice'
import counterReducer from '../features/counter/counterSlice'
import userReducer from '../features/user/userSlice'

export const store = configureStore({
  reducer: {
    userRequests: userRequestsReducer,
    counter: counterReducer,
    user: userReducer,
  },
})
