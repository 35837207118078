import React from 'react'
import { useSelector } from 'react-redux'
import UpgradeIcon from '../../assets/icons/UpgradeIcon.svg'
import {
  getAvailableQuota,
  getUserProfileData,
} from '../../features/user/userSlice'

const FormCallout = ({
  isAuthenticated,
  user,
  resendVerificationEmail,
  takeUserToStripe,
  loginWithRedirect,
  formPreservingAuth,
}) => {
  const userData = useSelector(getUserProfileData)
  const availableFreeEssaysQuota = useSelector(getAvailableQuota)

  return (
    <>
      {isAuthenticated ? (
        // TODO: Uncomment the next line and remove the always falsy logic which is the next to next line
        // !user?.email_verified ? (
        !user?.email_verified === user?.email_verified ? (
          // User is logged in but email is not verified. Do not let the user generate essays. Force them to verify their email!
          <div className="border border-orange-500 mt-2 rounded px-2 text-orange-900 bg-orange-50 py-2">
            An email has been sent to verify your account.{' '}
            <span
              className="underline underline-offset-2 cursor-pointer"
              onClick={resendVerificationEmail}
            >
              Click here
            </span>{' '}
            to resend.
            {/* You should have received an email to verify your account. For
      issues, email us{' '}
      <a
        href="mailto:derek@hubble.ai"
        className="underline underline-offset-1 cursor-pointer"
      >
        here
      </a> */}
          </div>
        ) : (
          // Logged in + Email verified
          !userData?.is_member && (
            <div className="md:hidden px-4 py-4 mt-6 bg-gradient-to-r from-[#0027B3]/10 to-[#F1CD61]/20 border-t-8 border-[#0027B3] shadow-md rounded-t-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*<span className="rounded-lg">
                <img src={LightBulbIcon} alt="Verification callout icon" className="items-center min-w-fit mr-4" />
          </span>*/}
              <div>
                <h3 className="text-2xl font-newkansas font-semibold">
                  Ready to Upgrade?
                </h3>
                <p className="pt-4 pb-4 text-md text-black-500 font-semibold">
                  We hope you've enjoyed Hubble so far! You have used{' '}
                  {Math.min(
                    process.env.REACT_APP_HUBBLE_FREE_ESSAYS,
                    process.env.REACT_APP_HUBBLE_FREE_ESSAYS -
                      parseInt(availableFreeEssaysQuota)
                  )}{' '}
                  of {process.env.REACT_APP_HUBBLE_FREE_ESSAYS} free essays and
                  summaries. You can upgrade for:
                </p>
                <div className="relative flex-auto">
                  <ul className="relative flex-auto list-disc">
                    <li className="my-2 mx-8 text-black font-normal leading-relaxed">
                      Unlimited Essays
                    </li>
                    <li className="my-2 mx-8 text-black font-normal leading-relaxed">
                      Unlimited Summaries
                    </li>
                  </ul>
                </div>
                <button
                  className="flex mt-8 w-full items-center justify-center bg-[#0027B3] text-white font-semibold text-normal px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={takeUserToStripe}
                >
                  <img src={UpgradeIcon} alt="Upgrade Icon" className="mr-2" />
                  Upgrade Now
                </button>
                {/* to subscribe and continue using hubble! */}
              </div>
            </div>
          )
        )
      ) : (
        // Not Logged in  -> Show login callout
        <div className="items-center border flex flex-row border-[#DEE5FC] rounded px-4 my-4 text-black-500 bg-[#F7F7FD] py-4">
          <div className="">
            <p className="font-semibold">
              Start writing essays with Hubble for free.
            </p>

            <button
              onClick={() => {
                formPreservingAuth()
                loginWithRedirect({ screen_hint: 'signup' })
              }}
              className="text-[#0027B3] cursor-pointer text-md font-semibold pt-2"
            >
              Register Now →
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default FormCallout
