import React from 'react'
import { useSelector } from 'react-redux'
import { getAvailableQuota } from '../../features/user/userSlice'

const TrialPlanOverviewCallout = () => {
  const availableFreeEssaysQuota = useSelector(getAvailableQuota)
  return (
    <div className="items-center mb-3 border-b pb-3 hidden">
      <span className="  text-sm font-semibold px-2 py-0.5 mr-2 rounded h-fit">
        Trial Plan Overview
        <p className="text-sm font-normal text-gray-500">
          You have used{' '}
          {Math.min(
            process.env.REACT_APP_HUBBLE_FREE_ESSAYS,
            process.env.REACT_APP_HUBBLE_FREE_ESSAYS - availableFreeEssaysQuota
          )}{' '}
          of {process.env.REACT_APP_HUBBLE_FREE_ESSAYS} free essays and
          summaries.
        </p>
      </span>
      {/* <h1 className="font-normal text-gray-500 text-md"></h1> */}
    </div>
  )
}

export default TrialPlanOverviewCallout
