import { Dialog, Transition } from '@headlessui/react'
import { useEffect } from 'react'
import { Fragment, useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import {
  CheckIcon,
  EssayIcon,
  SummaryIcon,
  ThreeDotsHorizontalIcon,
  TrashIcon,
  UpgradeIcon,
} from '../../assets/icons'
import { MOBILE_DELETE_OUTPUT_ACTION, OUTPUT_TYPE } from '../../constants'
import { processCompletionData } from '../../utils/generatedCompletionHelpers'
import GeneratedAt from '../common/GeneratedAt'

export const MobileHistoricalItemActionModal = (
  isOpen = false,
  setShowMobileEssayActionModal,
  selectedCompletion,
  modalTitle,
  deleteCompletion
) => {
  const closeModal = () => {
    setShowMobileEssayActionModal(false)
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed bottom-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen transform overflow-hidden bg-[#FBFBFA] p-6 text-left align-middle transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 border-b"
                  >
                    <div className="pb-4 flex flex-row justify-between items-center">
                      <h2 className="font-medium text-md">Actions</h2>
                      <button
                        onClick={closeModal}
                        // ph-attr={MOBILE_MY_ACCOUNT_MODAL_CLOSE_CLICK}
                      >
                        <MdOutlineClose className="w-5 h-5 text-[#9B9B9B]" />
                      </button>
                    </div>
                  </Dialog.Title>

                  {/* Modal Body */}
                  <div className="overflow-hidden rounded-lg ">
                    <div className="p-4 flex flex-col justify-center gap-6">
                      {/* Essay related information */}
                      <div className="flex flex-row items-center">
                        {selectedCompletion?.output_type ===
                          OUTPUT_TYPE.ACADEMIC_ESSAY ||
                        selectedCompletion?.output_type ===
                          OUTPUT_TYPE.TEXT_COMPLETION ? (
                          <EssayIcon className="mr-3 w-6 h-6" />
                        ) : (
                          <SummaryIcon className="mr-3 w-6 h-6" />
                        )}

                        <div className="flex flex-col">
                          <h1 className="px-2 -mb-1 text-lg">
                            {modalTitle?.length <= 40
                              ? modalTitle
                                ? modalTitle
                                : 'No Prompt'
                              : modalTitle?.substr(0, 40) + '...'}
                          </h1>

                          <GeneratedAt
                            selectedCompletion={selectedCompletion}
                          />
                        </div>
                      </div>

                      {/* Action Buttons */}
                      <div className="flex flex-col w-full rounded-md divide-y border bg-white">
                        <div
                          className="flex flex-row items-center p-4 gap-2"
                          onClick={deleteCompletion}
                          ph-attr={MOBILE_DELETE_OUTPUT_ACTION}
                        >
                          <TrashIcon className="stroke-[#CC0033]" />
                          <span className="ml-2 text-red-500">
                            Delete document
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default function HistoricalItemActionButton({
  selectedCompletion,
  deleteCompletion,
  takeUserToStripe,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    const { completionTitle } = processCompletionData(selectedCompletion)
    setModalTitle(completionTitle)
    // console.log('Selected completion: ', selectedCompletion)
  }, [selectedCompletion])

  return (
    <>
      <div className="sticky flex items-center justify-center">
        <ThreeDotsHorizontalIcon onClick={openModal} />
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed bottom-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen transform overflow-hidden bg-[#FBFBFA] p-6 text-left align-middle transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 border-b"
                  >
                    <div className="pb-4 flex flex-row justify-between items-center">
                      <h2 className="font-medium text-md">Actions</h2>
                      <button
                        onClick={closeModal}
                        // ph-attr={MOBILE_MY_ACCOUNT_MODAL_CLOSE_CLICK}
                      >
                        <MdOutlineClose className="w-5 h-5 text-[#9B9B9B]" />
                      </button>
                    </div>
                  </Dialog.Title>

                  {/* Modal Body */}
                  <div className="overflow-hidden rounded-lg ">
                    <div className="py-4 flex flex-col justify-center gap-6">
                      {/* Essay related information */}
                      <div className="flex flex-row items-center">
                        {selectedCompletion?.output_type ===
                          OUTPUT_TYPE.ACADEMIC_ESSAY ||
                        selectedCompletion?.output_type ===
                          OUTPUT_TYPE.TEXT_COMPLETION ? (
                          <EssayIcon className="mr-3 w-6 h-6" />
                        ) : (
                          <SummaryIcon className="mr-3 w-6 h-6" />
                        )}

                        <div className="flex flex-col">
                          <h1 className="px-2 text-lg -mb-1 font-semibold">
                            {modalTitle?.length <= 40
                              ? modalTitle
                                ? modalTitle
                                : 'No Prompt'
                              : modalTitle?.substr(0, 40) + '...'}
                          </h1>

                          <GeneratedAt
                            selectedCompletion={selectedCompletion}
                          />
                        </div>
                      </div>

                      {/* Action Buttons */}
                      <div className="flex flex-col w-full rounded-md divide-y border bg-white">
                        <div
                          className="flex flex-row items-center p-4 gap-2"
                          onClick={deleteCompletion}
                          ph-attr={MOBILE_DELETE_OUTPUT_ACTION}
                        >
                          <TrashIcon className="stroke-[#CC0033]" />
                          <span className="ml-2 text-red-500">
                            Delete document
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* <MobileHistoricalItemActionModal
        isOpen={isOpen}
        setShowMobileEssayActionModal={setIsOpen}
        selectedCompletion={selectedCompletion}
        modalTitle={modalTitle}
        deleteCompletion={deleteCompletion}
      /> */}
    </>
  )
}
