import React from 'react'
import { LoaderIcon } from '../assets/icons'

const Loader = () => {
  return (
    <div className="flex flex-col overflow-hidden items-center justify-center w-screen h-screen space-y-4">
      <LoaderIcon className="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-[#0027B3]" />
      <span className="text-[#0027B3] text-3xl">loading our ai...</span>
      <span className="sr-only">Loading Hubble.ai</span>
    </div>
  )
}

export default Loader
