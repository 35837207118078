import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import dayjs from 'dayjs'

const USERDATA_ENDPOINT =
  process.env.REACT_APP_HUBBLE_API_BASE_URL + '/logged_in_user'
const FREE_OUTPUTS_REMAINING_ENDPOINT =
  process.env.REACT_APP_HUBBLE_API_BASE_URL + '/free_outputs_remaining'
const TOTAL_GENERATED_OUTPUTS_ENDPOINT =
  process.env.REACT_APP_HUBBLE_API_BASE_URL + '/output_count'

const initialState = {
  userData: {
    profile: {},
    availableQuota: null,
    renewalDate: null,
    totalGeneratedOutputs: null,
  },
  isLoading: false, // Boolean: true or false
  hasErrored: false, // Boolean: true or false
  errorMessage: '', //Descriptive error message to show the user!
}

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async (accessToken) => {
    try {
      const userDataResponse = await axios.get(USERDATA_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return userDataResponse?.data
    } catch (err) {
      console.log('Error message = ', err)
    }
  }
)

export const fetchAvailableFreeQuota = createAsyncThunk(
  'user/fetchAvailableFreeQuota',
  async (accessToken) => {
    try {
      const freeOutputsRemainingQuota = await axios.get(
        FREE_OUTPUTS_REMAINING_ENDPOINT,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      return freeOutputsRemainingQuota?.data
    } catch (err) {
      console.log('Error message = ', err)
    }
  }
)

export const fetchGeneratedOutputsCount = createAsyncThunk(
  'user/fetchGeneratedOutputsCount',
  async (accessToken) => {
    try {
      const userDataResponse = await axios.get(
        TOTAL_GENERATED_OUTPUTS_ENDPOINT,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      return userDataResponse?.data
    } catch (err) {
      console.log('Error message = ', err)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // updateAccessToken: (state, action) => {
    //   state.userData.accessToken = action?.payload?.accessToken
    // },
    updateProfileData: (state, action) => {
      state.userData.profile = { ...action.payload.userData }
    },
    updateAvailableQuota: (state, action) => {
      state.userData.availableQuota = action.payload.free_outputs_remaining
    },
    updateAvailableQuotaByValue: (state, action) => {
      state.userData.availableQuota += action.payload
    },
    updateRenewalDate: (state, action) => {
      state.userData.renewalDate = action.payload.renewalDate
    },
    updateTotalGeneratedOutputs: (state, action) => {
      state.userData.totalGeneratedOutputs = action.payload.xyz
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserData.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.hasErrored = true
        state.errorMessage = action?.error?.message
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.userData.profile = action.payload
        const daysForQuotaRenewal =
          31 -
          (dayjs().diff(dayjs(state.userData.profile.created_at), 'day') % 31)
        state.userData.renewalDate = dayjs().add(daysForQuotaRenewal, 'day')
        state.userData.renewalDate = ''
      })
      .addCase(fetchAvailableFreeQuota.fulfilled, (state, action) => {
        state.userData.availableQuota =
          action.payload.data.free_outputs_remaining
      })
      .addCase(fetchGeneratedOutputsCount.fulfilled, (state, action) => {
        state.userData.totalGeneratedOutputs = action.payload.output_count
      })
  },
})

// State getters for better data initialization
export const getUserProfileData = (state) => state?.user?.userData?.profile
export const getAvailableQuota = (state) => state.user.userData.availableQuota
export const getQuotaRenewalDate = (state) => state.user.userData.renewalDate
export const getTotalGeneratedOutputsCount = (state) =>
  state.user.userData.totalGeneratedOutputs
export const getIsUserDataLoading = (state) => state?.isLoading

export const {
  // updateAccessToken,
  updateProfileData,
  updateAvailableQuota,
  updateRenewalDate,
  updateTotalGeneratedOutputs,
  updateAvailableQuotaByValue,
} = userSlice.actions

export default userSlice.reducer
